/**
 * The task in awork
 */
import { User } from '@awork/features/user/models/user.model'
import { TaskList } from '@awork/features/task/models/task-list.model'
import { TaskStatus } from '@awork/features/task/models/task-status.model'
import { isArrayOfStrings, mapTagsArr, Project } from '@awork/features/project/models/project.model'
import { Company } from '@awork/features/company/models/company.model'
import { TaskListUI } from '@awork/features/task/models/task-list-ui.model'
import { WorkType } from '@awork/features/task/models/work-type.model'
import { TaskDependency } from '@awork/features/task/models/task-dependency.model'
import { Tag } from '@awork/_shared/models/tag.model'
import { differenceInMinutes } from '@awork/_shared/functions/date-fns-wrappers'
import { ITaskBase, TaskBase } from '@awork/features/task/models/task.model.base'
import { differenceInCalendarDays } from 'date-fns'
import { TaskAssignee } from '@awork/features/task/models/task-assignee.model'
import { ValidEntityTypes } from '@awork/features/task/services/task-service/types'
import { CustomField } from '@awork/features/project/models/custom-field.model'

export type ValidTaskBaseTypes = 'private' | 'projecttask'
export type ValidTaskEntityTypes = Project | User

export const DEFAULT_TASK_START_HOUR = 9
export const DEFAULT_TASK_END_HOUR = 18

interface ITask {
  correlationId: string
  baseType: ValidTaskBaseTypes
  typeOfWorkId: string
  typeOfWork: WorkType
  taskStatus: Partial<TaskStatus>
  project: Project
  hasAttachment: boolean
  trackedDuration: number
  totalTrackedDuration: number
  totalPlannedDuration: number
  totalRemainingDuration: number
  projectId: string
  lists: TaskList[]
  entityId: string
  updatedByUser: User
  createdByUser: User
  createdOn: Date
  updatedOn: Date
  description: string
  isPrio: boolean
  remainingDuration: number
  taskStatusId: string
  companyId: string
  company: Company
  fileId: string
  userId: string
  user: User
  entity: ValidTaskEntityTypes
  tags: Tag[]
  closedOn?: Date
  justCheckedOff?: boolean
  order?: number
  subtaskOrder?: number
  resourceVersion: number
  storeVersion: number
  score?: number
  predecessors?: TaskDependency[]
  successors?: TaskDependency[]
  isRecurring: boolean
  checklistItemsCount: number
  checklistItemsDoneCount: number
  trackById: string
  isCompletelyScheduled: boolean
  taskSchedulesCount: number
  modifiedOn?: number
  numberOfSubtasks?: number
  commentCount: number
  customFields?: CustomField[]
}

export class Task extends TaskBase implements ITask {
  correlationId: string
  baseType: ValidTaskBaseTypes
  typeOfWorkId: string
  typeOfWork: WorkType
  taskStatus: TaskStatus
  assignees: TaskAssignee[]
  project: Project
  hasAttachment: boolean
  trackedDuration: number
  totalTrackedDuration: number
  totalPlannedDuration: number
  totalRemainingDuration: number
  projectId: string
  lists: TaskList[]
  entityId: string
  updatedByUser: User
  createdByUser: User
  createdOn: Date
  updatedOn: Date
  description: string
  isPrio: boolean
  remainingDuration: number
  taskStatusId: string
  companyId: string
  company: Company
  fileId: string
  userId: string
  user: User
  entity: ValidTaskEntityTypes
  tags: Tag[]
  closedOn?: Date
  justCheckedOff?: boolean
  order?: number
  subtaskOrder?: number
  resourceVersion: number // the version from the API MV
  storeVersion: number // version of the object for the store
  score?: number // optional property to store the search score
  predecessors?: TaskDependency[]
  successors?: TaskDependency[]
  isRecurring: boolean
  checklistItemsCount: number
  checklistItemsDoneCount: number
  trackById: string
  isCompletelyScheduled: boolean
  taskSchedulesCount: number
  modifiedOn?: number
  numberOfSubtasks?: number
  typeName = 'Task'
  isSaving: boolean
  commentCount: number
  customFields?: CustomField[]

  constructor(task: Partial<ITask & ITaskBase>) {
    super(task)

    if (this.taskStatus) {
      this.taskStatus = new TaskStatus(this.taskStatus)
    }
    if (this.baseType && !this.entity) {
      this.setEntity()
    }

    if (!this.lists) {
      this.lists = []
    }
  }

  /**
   * Maps the task object and nested objects
   * @param {Task} task - The task to be mapped
   * @return {Task} - The mapped task
   */
  static mapTask(task: Task): Task {
    const mappedTask = new Task(task)

    mappedTask.typeOfWork = task.typeOfWork ? new WorkType(task.typeOfWork) : null
    mappedTask.taskStatus = task.taskStatus ? new TaskStatus(task.taskStatus) : null
    mappedTask.project = task.project ? Project.mapProject(task.project) : null
    mappedTask.lists = task.lists ? task.lists.map(list => new TaskList(list)) : []
    mappedTask.successors = task.successors ? task.successors.map(dep => dep as TaskDependency) : []
    mappedTask.predecessors = task.predecessors ? task.predecessors.map(dep => dep as TaskDependency) : []
    mappedTask.assignees = task.assignees
      ? task.assignees.map(assignee => new TaskAssignee(assignee, 'Task.mapTask'))
      : []

    if (task.project && task.project.company) {
      mappedTask.project.company = new Company(task.project.company)
    }

    // Dates
    mappedTask.dueOn = mappedTask.dueOn ? new Date(mappedTask.dueOn) : null
    mappedTask.startOn = mappedTask.startOn ? new Date(mappedTask.startOn) : null
    mappedTask.closedOn = mappedTask.closedOn ? new Date(mappedTask.closedOn) : null
    mappedTask.createdOn = mappedTask.createdOn ? new Date(mappedTask.createdOn) : null

    // Map array of strings to array of tags: Tag[].
    // To be removed later after the release and make sure all the stores are updated
    if (mappedTask.tags && isArrayOfStrings(mappedTask.tags)) {
      mappedTask.tags = mapTagsArr(mappedTask.tags)
    }

    if (mappedTask.project?.tags && isArrayOfStrings(mappedTask.project.tags)) {
      mappedTask.project.tags = mapTagsArr(mappedTask.project.tags)
    }

    if (mappedTask.customFields) {
      mappedTask.customFields = mappedTask.customFields.map(field => new CustomField(field))
    }

    return mappedTask
  }

  static isTask(task: any): task is Task {
    return (task as Task)?.typeName === 'Task'
  }

  get entityType(): ValidEntityTypes {
    return getEntityType(this.baseType)
  }

  get listUrl(): string {
    const type = this.baseType === 'projecttask' ? 'projects' : this.baseType
    return this.isPrivate ? `tasks/overview` : `${type}/${this.entityId}/tasks/list`
  }

  get urlName(): string {
    return decodeURIComponent(this.name.replace(/ /g, '-'))
  }

  /**
   * For private, done tasks, the order value is not the default property.
   * It is being calculated depending on the time the task was closed
   */
  get privateTaskOrder(): number {
    let returnVal = 9999
    if (this.isPrivate) {
      if (this.isDone && !this.justCheckedOff && this.closedOn) {
        const orderByDateDiff = Math.round((this.closedOn.getTime() - new Date().getTime()) / 1000)
        returnVal = orderByDateDiff
      } else {
        returnVal = this.order
      }
    } else {
      returnVal = this.order
    }
    return returnVal
  }

  /**
   * Returns the description without html and with a limit of 100 characters
   */
  get descriptionPlainAbstract(): string {
    return this.description ? this.description.replace(/<(?:.|\n)*?>/gm, '').slice(0, 99) : ''
  }

  /**
   * Returns if the task is in a done status
   */
  get isDone(): boolean {
    return this.taskStatus?.type === 'done'
  }

  /**
   * Returns if the task is in a done status
   */
  get isDoneAndNotJustCheckedOff(): boolean {
    return this.taskStatus?.type === 'done' && !this.justCheckedOff
  }

  /**
   * Returns if the task have been marked as done in before the last 30 days
   */
  get isOldDoneTask(): boolean {
    if (!this.isDone) {
      return false
    }

    if (this.closedOn) {
      return differenceInCalendarDays(new Date(), this.closedOn) > 30
    }

    // All done tasks should have a closedOn date, in case it doesn't we consider it as not old
    return false
  }

  /**
   * Returns if the task is private
   * @returns {boolean}
   */
  get isPrivate(): boolean {
    return this.baseType === 'private'
  }

  /**
   * Returns the number of minutes the task is due in
   */
  get dueIn(): number {
    const dueDate = this.dueOn ? new Date(this.dueOn) : null
    if (dueDate) {
      const now = new Date()
      return differenceInMinutes(dueDate, now)
    } else {
      return 999999999
    }
  }

  get isRecurringParentOrChild(): boolean {
    return this.isRecurring || !!this.createdFromTaskId
  }

  /**
   * Sets the task assignees
   * @param {TaskAssignee[]} users
   */
  setAssignees(users: TaskAssignee[] = []): void {
    if (!users.length) {
      this.assignees = []
      return
    }

    if (this.isPrivate) {
      const privateTaskAssignee = users[0]

      this.assignees = privateTaskAssignee ? [privateTaskAssignee] : []
      this.entity = privateTaskAssignee
      this.entityId = privateTaskAssignee.id
    } else {
      this.assignees = users
    }
  }

  /**
   * Sets the entityId of the task according to the baseType
   */
  setEntityId(): void {
    if (this.baseType === 'projecttask') {
      this.projectId = this.entity.id
      this.project = this.entity as Project
    } else {
      this.userId = this.entity.id
      this.user = this.entity as TaskAssignee

      this.assignees = [this.user]
    }
    this.entityId = this.entity.id
  }

  /**
   * Sets the entity of the task according to the baseType
   */
  setEntity(): void {
    this.entity = this.baseType === 'projecttask' ? this.project : this.privateTaskAssignee
    this.entityId = this.entity?.id
  }

  get privateTaskAssignee(): TaskAssignee {
    return this.isPrivate ? this.assignees?.[0] : null
  }

  /**
   * Determines if the current user can edit the task
   * @param {User} currentUser
   * @param {boolean} permission
   * @returns {boolean}
   */
  canEdit(currentUser: User, permission: boolean): boolean {
    return this.id && (permission || this.isUserAssigned(currentUser.id) || this.isPrivate)
  }

  /**
   * Returns whether is a private task that belongs to the current user
   */
  isOwnPrivateTask(currentUser: User): boolean {
    return this.isPrivate && this.isUserAssigned(currentUser.id)
  }

  /**
   * Returns whether is a task that belongs to the current user
   */
  isOwnTask(currentUser: User): boolean {
    return this.isUserAssigned(currentUser.id)
  }

  /**
   * Checks whether user is assigned to the task
   * @param {User} userId
   * @returns {boolean}
   */
  isUserAssigned(userId: string): boolean {
    if (!userId) {
      return false
    }

    return this.assignees?.some(({ id }) => id === userId)
  }

  /**
   * Compares a group of assignee with task's assignees
   * @param {TaskAssignee[]} assigneesToCompare
   * @returns {boolean}
   */
  hasSameAssignees(assigneesToCompare: TaskAssignee[]): boolean {
    if (!assigneesToCompare?.length && !this.assignees?.length) {
      return true
    }

    if (assigneesToCompare?.length !== this.assignees?.length) {
      return false
    }

    const usersIds = assigneesToCompare.map(user => user.id)

    return this.assignees.every(user => user?.id && usersIds.includes(user.id))
  }

  /**
   * Gets whether or not task has assignees
   * @returns {boolean}
   */
  get hasAssignees(): boolean {
    return !!this.assignees?.length
  }

  /**
   * Return true when a task is already planned
   * @returns {boolean}
   */
  get isPlanned(): boolean {
    return !!(this.startOn && this.dueOn)
  }

  /**
   * Returns the id which was used to create the task
   * it will return the trackById when the task has just been created optimistically
   * @returns {string}
   */
  get creationId(): string {
    return this.trackById || this.id
  }

  /**
   * Returns the assignee object by id
   * @param {string} assigneeId
   * @returns {TaskAssignee}
   */
  getAssigneeById(assigneeId: string): TaskAssignee {
    return this.assignees?.find(user => user.id === assigneeId)
  }

  /**
   * Returns the planned duration for one user of the task
   * @param {string} assigneeId
   * @returns {number}
   */
  getAssigneePlannedDuration(assigneeId: string): number {
    return this.hasAssignees ? this.plannedDuration / this.assignees.length : this.plannedDuration
  }

  /**
   * Handles logic and validation for assignees
   * @param {TaskAssignee} user
   * @param {boolean} replace
   */
  assignUser(user: TaskAssignee, replace = true): void {
    if (this.isUserAssigned(user.id)) {
      this.assignees = replace ? [user] : this.assignees
      return
    }

    this.assignees = replace || !this.assignees ? [user] : [...this.assignees, user]
    this.userId = user.id
    this.user = user
  }

  /**
   * Sets the current user to be the assignee if the task checked as "in-progress" with nobody assigned
   * @param {TaskAssignee} currentUser
   */
  assignUserOnProgress(currentUser: TaskAssignee): void {
    // TODO: [multi-user-assignment] TBD: will this logic still apply?
    if (this.taskStatus.type === 'progress' && !this.hasAssignees) {
      this.assignees = [currentUser]
    }
  }

  /**
   * Returns true if at least one of the assignees has name
   * @param {string} name
   * @returns {boolean}
   */
  hasAssigneeWithName(name: string): boolean {
    return this.assignees?.some?.(assignee => assignee.fullName?.toLowerCase().includes(name))
  }

  /**
   * Gives the order of the task within a list
   * @param {TaskListUI} listConfig
   * @returns {number} - the order of the task in the list
   */
  getListOrder(listConfig: TaskListUI): number {
    if (this.lists && (listConfig.type === 'list' || listConfig.type === 'privatelist')) {
      const list = this.lists.find(l => l.id === listConfig.entityId)
      return list ? list.orderOfTask : 0
    }
    return 0
  }

  /**
   * Gives the order of the task within a list
   * @param {TaskList} list
   * @returns {number} - the order of the task in the list
   */
  getOrderForTaskList(list: TaskList): number {
    if (this.lists && this.lists.length > 0 && list) {
      const foundList = this.lists.find(l => l.id === list.id)
      return foundList ? foundList.orderOfTask : 0
    } else {
      if (this.createdOn) {
        const creationDate = new Date(this.createdOn)
        return creationDate.getTime()
      }
    }
    return 0
  }

  /**
   * Sets the order of the task within a list
   * @param {TaskList} list
   */
  setOrderForTaskList(list: TaskList, order: number): void {
    if (this.lists) {
      const foundList = this.lists.find(l => l.id === list.id)
      if (foundList) {
        foundList.orderOfTask = order
      }
    }
  }

  getOrderableTaskStatusName(): string {
    const order = TaskStatus.types.find(t => t.key === this.taskStatus?.type).order
    return `${order}-${this.taskStatus.name}`
  }

  /**
   * Returns if task's dueOn date is between given dates
   * @param {string} earlierDate
   * @param {string} laterDate
   * @returns {boolean}
   */
  isDueDateBetween(earlierDate: string, laterDate: string): boolean {
    if (!this.dueOn) {
      return false
    }

    return laterDate > this.dueOn.toISOString() && earlierDate < this.dueOn.toISOString()
  }

  /**
   * Returns if task's dueOn date is later than given date
   * @param {string} date
   * @returns {boolean}
   */
  isDueDateLater(date: string): boolean {
    return this.dueOn?.toISOString() > date
  }

  /**
   * Returns if task's dueOn date is before than given date
   * @param {string} date
   * @returns {boolean}
   */
  isDueDateBefore(date: string): boolean {
    return this.dueOn?.toISOString() < date
  }
}

export function getEntityType(baseType: ValidTaskBaseTypes): ValidEntityTypes {
  return baseType === 'private' ? 'users' : 'projects'
}

export function getTaskPropertyNames(): Object {
  return {
    plannedduration: q.translations.ActivityLogComponent.entityProperties.plannedDuration,
    remainingduration: q.translations.ActivityLogComponent.entityProperties.remainingDuration,
    typeofworkid: q.translations.ActivityLogComponent.entityProperties.typeOfWorkId,
    taskstatusid: q.translations.ActivityLogComponent.entityProperties.taskStatusId,
    companyid: q.translations.entities.company,
    assigneeid: q.translations.entities.user,
    projectid: q.translations.entities.project,
    name: q.translations.ActivityLogComponent.entityProperties.name,
    description: q.translations.ActivityLogComponent.entityProperties.description,
    starton: q.translations.ActivityLogComponent.entityProperties.startDate,
    dueon: q.translations.ActivityLogComponent.entityProperties.dueDate,
    tags: q.translations.ActivityLogComponent.entityProperties.tags,
    trackedduration: q.translations.ActivityLogComponent.entityProperties.trackedDuration,
    isprio: q.translations.ActivityLogComponent.entityProperties.isPrio
  }
}

export function getTaskPropertyArticles(): Object {
  return {
    plannedduration: q.translations.common.articles.den,
    remainingduration: q.translations.common.articles.die,
    typeofworkid: q.translations.common.articles.die,
    taskstatusid: q.translations.common.articles.den,
    companyid: q.translations.common.articles.das,
    assigneeid: q.translations.common.articles.den,
    projectid: q.translations.common.articles.das,
    name: q.translations.common.articles.den,
    description: q.translations.common.articles.die,
    starton: q.translations.common.articles.das,
    dueon: q.translations.common.articles.die,
    tags: q.translations.common.articles.den,
    trackedduration: q.translations.common.articles.die,
    isprio: q.translations.common.articles.die
  }
}

export function getChecklistItemsPropertyArticles(): Object {
  return {
    isdone: q.translations.common.articles.die
  }
}

export function getChecklistItemsPropertyNames(): Object {
  return {
    checklistItems: '',
    isdone: q.translations.ActivityLogComponent.entityProperties.isDone
  }
}
