import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, inject } from '@angular/core'
import { animate, keyframes, style, transition, trigger } from '@angular/animations'
import { Router } from '@angular/router'
import { addDays, addYears } from '@awork/_shared/functions/date-fns-wrappers'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { PrintService } from '../../../_shared/services/print-service/print.service'
import { INpsUserSetting, NpsSettingActionType } from '@awork/_shared/models/user-setting.model'
import { UserSettingsService } from '@awork/_shared/services/user-settings-service/user-settings.service'
import { TrackingService } from '@awork/_shared/services/tracking-service/tracking.service'
import { TrackingEvent } from '@awork/_shared/services/tracking-service/events'
import { UserQuery } from '@awork/features/user/state/user.query'
import { ToastComponent } from '../../../_shared/components/ui-help/toast/toast.component'
import { ToastService } from '../../../_shared/services/toast-service/toast.service'
import { NgClass, NgFor, NgIf } from '@angular/common'
import { CloseButtonComponent } from '../../../_shared/components/icon-buttons/close-button/close-button.component'
import { BadgeComponent } from '../../../_shared/components/data-display/badge/badge.component'
import { MultilineTextFieldComponent } from '../../../_shared/components/inputs/multiline-text-field/multiline-text-field.component'
import { ButtonComponent } from '../../../_shared/components/buttons/button/button.component'

const MAX_NPS_SCORE = 10
const POSITIVE_SCORE_LIMIT = 8

@Component({
  selector: 'aw-nps-toast',
  templateUrl: './nps-toast.component.html',
  styleUrls: ['./nps-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgIf, NgClass, CloseButtonComponent, BadgeComponent, MultilineTextFieldComponent, ButtonComponent],
  animations: [
    trigger('slideOpen', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', opacity: '0' }),
        animate(
          '.2s ease-out',
          keyframes([
            style({ transform: 'translateY(-25%)', opacity: '0', offset: 0.75 }),
            style({ transform: 'translateY(0%)', opacity: '1', offset: 1 })
          ])
        )
      ])
    ]),
    trigger('expand', [
      transition(':enter', [style({ height: '0px' }), animate('.2s ease-out', style({ height: '210px' }))])
    ])
  ]
})
export class NpsToastComponent extends ToastComponent implements OnInit {
  @Input() npsRatingSetting: INpsUserSetting

  scores: { label: string; value: number }[] = Array(MAX_NPS_SCORE + 1)
    .fill(0)
    .map((_, i) => ({ label: `score${i}`, value: i }))
  selectedScore: number
  isNegativeScore = true
  feedbackText = ''
  npsTrackingSent = false

  translations = q.translations.NpsToastComponent

  npsToastTitle = this.translations.title
  positiveScoreFeedbackTitle = this.translations.scoreFeedbackTitle.positive
  negativeScoreFeedbackTitle = this.translations.scoreFeedbackTitle.negative
  scoreFeedbackTitle = ''

  positiveScoreFeedbackPlaceholder = this.translations.scoreFeedbackPlaceholder.positive
  negativeScoreFeedbackPlaceholder = this.translations.scoreFeedbackPlaceholder.negative
  scoreFeedbackPlaceholder = ''

  positiveDoneMessage = ''
  negativeDoneMessage = this.translations.doneMessage.negative

  private toastService = inject(ToastService)

  constructor(
    public el: ElementRef,
    protected router: Router,
    protected browserService: BrowserService,
    public printService: PrintService,
    private userSettingsService: UserSettingsService,
    private trackingService: TrackingService,
    private userQuery: UserQuery
  ) {
    super(el, router, browserService, printService)
  }

  ngOnInit(): void {}

  /**
   * Selects a rating and adds the correct messaging
   * @param {number} score - The selected rating score
   */
  onScoreChange(score: number): void {
    this.selectedScore = score
    this.isNegativeScore = this.selectedScore < POSITIVE_SCORE_LIMIT

    this.positiveDoneMessage =
      this.selectedScore === 9
        ? this.translations.doneMessage.positiveScore9
        : this.translations.doneMessage.positiveScore10

    this.scoreFeedbackTitle = this.isNegativeScore ? this.negativeScoreFeedbackTitle : this.positiveScoreFeedbackTitle
    this.scoreFeedbackPlaceholder = this.isNegativeScore
      ? this.negativeScoreFeedbackPlaceholder
      : this.positiveScoreFeedbackPlaceholder
  }

  /**
   * Closes the NPS toast. Tracks any feedback selected and updates the user setting
   * to be submitted or dismissed
   */
  onClose(): void {
    if (!this.npsTrackingSent) {
      this.updateNpsRatingSetting()
      this.trackNpsRating()
    }
    this.close()
  }

  /**
   * Updates the feedback text on user input
   * @param {string} feedback - The feedback text
   */
  onFeedbackInput(feedback: string): void {
    this.feedbackText = feedback
  }

  /**
   * Sets the state to feedback submitted and triggers a close
   */
  onSendFeedbackClick(): void {
    this.onClose()
    const doneMessage = this.isNegativeScore ? this.negativeDoneMessage : this.positiveDoneMessage

    if (!this.isNegativeScore) {
      this.toastService.show(doneMessage, {
        type: 'thankYou',
        action: () => this.goToWriteReview(),
        actionText: this.translations.writeReview
      })
    } else {
      this.toastService.show(doneMessage, { type: 'confirmation' })
    }
  }

  /**
   * Sends a tracking event with the selected score and feedback text
   */
  trackNpsRating(): void {
    if (typeof this.selectedScore !== 'number') {
      return
    }

    const trackingData = {
      score: this.selectedScore,
      comment: this.feedbackText
    }
    const userId = this.userQuery.getCurrentUser().id

    this.trackingService.identifyUser(userId, { nps: trackingData.score })
    this.trackingService.trackEvent(TrackingEvent.npsRated, trackingData, null, userId)
    this.npsTrackingSent = true
  }

  /**
   * Updates the nps user setting. When there is a feedback, sets it to submitted
   * and when no feedback sets a next reminder to show the nps toast
   */
  updateNpsRatingSetting(): void {
    let npsRatingSetting: INpsUserSetting = {
      lastActionType: this.selectedScore >= 0 ? NpsSettingActionType.submitted : NpsSettingActionType.dismissed
    }

    if (npsRatingSetting.lastActionType === NpsSettingActionType.dismissed) {
      npsRatingSetting.nextReminder = this.npsRatingSetting.nextReminder
        ? addYears(new Date(), 1)
        : addDays(new Date(), 3)
    } else if (npsRatingSetting.lastActionType === NpsSettingActionType.submitted) {
      npsRatingSetting.nextReminder = addYears(new Date(), 1)
    }

    this.userSettingsService.setUserSetting('npsRating', JSON.stringify(npsRatingSetting)).subscribe()
  }

  /**
   * Opens a new window to the third party review link
   */
  goToWriteReview(): void {
    const link =
      this.selectedScore === 9 ? this.translations.writeReviewLinkScore9 : this.translations.writeReviewLinkScore10
    window.open(link, '_blank')
  }
}
