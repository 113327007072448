import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { PositionService } from '../../services/position-service/position.service'
import { TooltipComponent } from './components/tooltip.component'
import {
  Directive,
  Input,
  ComponentRef,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core'
import { DynamicRefService } from '../../services/dynamic-ref-service/dynamic-ref.service'

@Directive({
  selector: '[awTooltip]',
  standalone: true
})
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input() awTooltip: string
  @Input() showDelay: number
  @Input() actionTooltip: boolean
  @Input() fromTop = false
  @Input() suppress = false
  @Input() offset = 0
  @Input() customOffsetX = 0
  @Input() tooltipIcon: string
  @Input() tooltipIconColor = 'steel'
  @Input() firstKey: string | number
  @Input() secondKey: string | number
  @Input() thirdKey: string | number
  @Input() tooltipWidth: number

  private visible = false
  private tooltipRef: ComponentRef<TooltipComponent>

  // this variable is necessary to avoid the tooltip being created,
  // if the mouse already left the object again within the delay
  private mouseEntered = false

  constructor(
    private element: ElementRef,
    private _positionService: PositionService,
    private _browserService: BrowserService,
    private dynRefSvc: DynamicRefService
  ) {}

  ngOnInit(): void {
    this.setDelay()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.suppress?.previousValue && changes.suppress?.currentValue) {
      this.hide()
    }

    if (changes.awTooltip && this.visible && this.tooltipRef) {
      this.tooltipRef.instance.content = this.awTooltip
    }
  }

  ngOnDestroy() {
    // destroy the tooltip comp, when the referencing component is being removed
    this.visible = false
    if (this.tooltipRef) {
      this.tooltipRef.destroy()
    }
  }

  /**
   * Sets the delay in case is not set as input
   */
  private setDelay(): void {
    if (!this.showDelay) {
      this.showDelay = this.actionTooltip ? 200 : 1000
    }
  }

  // create component on mouse enter after a delay
  @HostListener('mouseenter')
  show() {
    if (this._browserService.isMouseDevice() && this.awTooltip && !this.suppress) {
      if (this.awTooltip.trim && !this.awTooltip.trim()) {
        return
      }

      this.mouseEntered = true

      setTimeout(() => {
        if (this.mouseEntered === true && this.element.nativeElement) {
          if (this.visible) {
            return
          }
          this.visible = true

          const [tooltipRef, tooltip] = this.dynRefSvc.create(TooltipComponent)
          this.tooltipRef = tooltipRef
          tooltip.content = this.awTooltip
          tooltip.action = this.actionTooltip
          tooltip.fromTop = this.fromTop
          tooltip.icon = this.tooltipIcon
          tooltip.iconColor = this.tooltipIconColor
          tooltip.firstKey = this.firstKey
          tooltip.secondKey = this.secondKey
          tooltip.thirdKey = this.thirdKey

          const elPos = this._positionService.offset(this.element.nativeElement, 'TooltipDirective#show')

          if (!this.isElementPositionValid(elPos)) {
            return
          }

          const toolTipHeight = 36
          const doc: Document = this._browserService.getDocument()
          let leftPixelOffset: number
          let rightPixelOffset: number

          if (this.actionTooltip) {
            // Position the tooltip at the bottom if it's a action tooltip (mainly associated with
            // button icon)
            // Timeout necessary to render the tooltip, to get its width
            setTimeout(() => {
              if (
                this.tooltipRef.instance &&
                this.tooltipRef.instance.actionTip &&
                this.tooltipRef.instance.actionTip.nativeElement &&
                this.element.nativeElement &&
                (elPos.left !== 0 || elPos.top !== 0)
              ) {
                const tooltipEl = this.tooltipRef.instance.actionTip.nativeElement
                const tipPos = this._positionService.offset(tooltipEl, 'TooltipDirective#show')

                // checking if the tool tip exceeds the left side of the screen
                leftPixelOffset = elPos.left + elPos.width / 2 - tipPos.width / 2
                const exceedingScreenLeft = leftPixelOffset < 0 ? Math.abs(leftPixelOffset) + 2 : 0

                // checking if the tool tip exceeds the right side of the screen
                rightPixelOffset = doc.body.clientWidth - (elPos.left + elPos.width / 2 + tipPos.width / 2)
                const exceedingScreenRight = rightPixelOffset < 0 ? Math.abs(rightPixelOffset) + 2 : 0

                tooltip.left = leftPixelOffset + exceedingScreenLeft - exceedingScreenRight + 'px'
                tooltip.callOutX = exceedingScreenLeft - exceedingScreenRight + 'px'
                if (this._positionService.isFixedPositioned(this.element.nativeElement) && doc) {
                  // Check if the tooltip wants to appear on the top of the element or from the bottom
                  if (!this.fromTop) {
                    tooltip.top = elPos.top + elPos.height + 15 + doc.documentElement.scrollTop + this.offset + 'px'
                  } else {
                    tooltip.top = elPos.top - elPos.height + doc.documentElement.scrollTop + this.offset - 15 + 'px'
                  }
                  tooltip.fixed = true
                } else {
                  if (!this.fromTop) {
                    tooltip.top = elPos.top + elPos.height + 15 + this.offset + 'px'
                  } else {
                    tooltip.top = elPos.top - elPos.height + 55 + this.offset + 'px'
                  }
                }
              }
            })
          } else {
            // Position the tooltip to the right if it's a data tooltip
            leftPixelOffset = elPos.left + this.customOffsetX + elPos.width
            tooltip.left = leftPixelOffset + 'px'

            tooltip.width = this.tooltipWidth

            if (this._positionService.isFixedPositioned(this.element.nativeElement) && doc) {
              tooltip.top = elPos.top + elPos.height / 2 - toolTipHeight / 2 + doc.documentElement.scrollTop + 'px'
              tooltip.fixed = true
            } else {
              tooltip.top = elPos.top + elPos.height / 2 - toolTipHeight / 2 + 'px'
            }
          }

          // check if tooltip.left exceeds screen bounds, or move to other side
          if (doc.documentElement.clientWidth - leftPixelOffset < elPos.width) {
            // the value should actually be calculated, but we can't because the element isn't rendered yer
            tooltip.left = leftPixelOffset - 180 + 'px'
          }
        }
      }, this.showDelay)
    }
  }

  // destroy tooltip component on mouse leave
  @HostListener('mouseleave')
  @HostListener('click')
  hide() {
    if (this._browserService.isMouseDevice()) {
      this.mouseEntered = false
      if (!this.visible) {
        return
      }
      this.visible = false
      this.tooltipRef.destroy()
    }
  }

  /**
   * Checks if the coordinates of the referencing component are valid
   * @param elPos element position
   * @returns {boolean}
   */
  private isElementPositionValid(elPos): boolean {
    return (elPos.width > 0 && elPos.height > 0) || (elPos.top > 0 && elPos.left > 0)
  }
}
