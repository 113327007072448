import {
  addMilliseconds as _addMilliseconds,
  addDays as _addDays,
  addWeeks as _addWeeks,
  addMonths as _addMonths,
  addQuarters as _addQuarters,
  addYears as _addYears,
  addHours as _addHours,
  addSeconds as _addSeconds,
  subDays as _subDays,
  subMonths as _subMonths,
  subHours as _subHours,
  subSeconds as _subSeconds,
  compareAsc as _compareAsc,
  compareDesc as _compareDesc,
  format as _format,
  isSameDay as _isSameDay,
  isSameWeek as _isSameWeek,
  isSameISOWeek as _isSameISOWeek,
  isSameMonth as _isSameMonth,
  isSameYear as _isSameYear,
  isEqual as _isEqual,
  isToday as _isToday,
  isTomorrow as _isTomorrow,
  isYesterday as _isYesterday,
  isValid as _isValid,
  isWeekend as _isWeekend,
  isSunday as _isSunday,
  isMonday as _isMonday,
  isTuesday as _isTuesday,
  isWednesday as _isWednesday,
  isThursday as _isThursday,
  isFriday as _isFriday,
  isSaturday as _isSaturday,
  isWithinInterval as _isWithinInterval,
  isBefore as _isBefore,
  isAfter as _isAfter,
  isPast as _isPast,
  differenceInDays as _differenceInDays,
  differenceInMinutes as _differenceInMinutes,
  differenceInMilliseconds as _differenceInMilliseconds,
  differenceInCalendarDays as _differenceInCalendarDays,
  differenceInSeconds as _differenceInSeconds,
  differenceInHours as _differenceInHours,
  differenceInWeeks as _differenceInWeeks,
  differenceInMonths as _differenceInMonths,
  differenceInYears as _differenceInYears,
  setHours as _setHours,
  setDay as _setDay,
  setMonth as _setMonth,
  setISOWeek as _setISOWeek,
  setQuarter as _setQuarter,
  getQuarter as _getQuarter,
  getHours as _getHours,
  getMonth as _getMonth,
  getYear as _getYear,
  getISODay as _getISODay,
  getISOWeek as _getISOWeek,
  startOfDay as _startOfDay,
  startOfToday as _startOfToday,
  startOfWeek as _startOfWeek,
  startOfMonth as _startOfMonth,
  startOfYear as _startOfYear,
  lastDayOfMonth as _lastDayOfMonth,
  endOfDay as _endOfDay,
  endOfToday as _endOfToday,
  endOfTomorrow as _endOfTomorrow,
  endOfYesterday as _endOfYesterday,
  endOfMonth as _endOfMonth,
  endOfQuarter as _endOfQuarter,
  endOfYear as _endOfYear,
  startOfQuarter as _startOfQuarter,
  endOfWeek as _endOfWeek,
  isDate as _isDate,
  isFirstDayOfMonth as _isFirstDayOfMonth,
  areIntervalsOverlapping as _areIntervalsOverlapping,
  isThisMonth as _isThisMonth
} from 'date-fns'
import { getWeekStartByLocale } from 'weekstart'

export function addMilliseconds(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _addMilliseconds(date, amount)
}

export function addDays(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _addDays(date, amount)
}

export function addWeeks(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _addWeeks(date, amount)
}

export function addMonths(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _addMonths(date, amount)
}

export function addQuarters(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _addQuarters(date, amount)
}

export function addYears(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _addYears(date, amount)
}

export function addHours(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _addHours(date, amount)
}

export function addSeconds(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _addSeconds(date, amount)
}

export function subDays(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _subDays(date, amount)
}

export function subMonths(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _subMonths(date, amount)
}

export function subHours(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _subHours(date, amount)
}

export function subSeconds(date: Date | string | number, amount: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _subSeconds(date, amount)
}

export function compareAsc(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _compareAsc(dateLeft, dateRight)
}

export function compareDesc(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _compareDesc(dateLeft, dateRight)
}

export function format(
  date: Date | number | string,
  formatString: string,
  options?: {
    locale?: Locale
    weekStartsOn?: number
    firstWeekContainsDate?: number
    useAdditionalWeekYearTokens?: boolean
    useAdditionalDayOfYearTokens?: boolean
  }
): string {
  let formatOptions

  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (options) {
    const dateFnsOptions = options ? getDateFnsOptions(options.locale, options.weekStartsOn) : null
    formatOptions = { options, ...dateFnsOptions }
  }

  return _format(date, formatString, formatOptions)
}

export function isSameDay(dateLeft: Date | string | number, dateRight: Date | string | number): boolean {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _isSameDay(dateLeft, dateRight)
}

export function isSameWeek(dateLeft: Date | string | number, dateRight: Date | string | number): boolean {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _isSameWeek(dateLeft, dateRight, { weekStartsOn: startOfWeekIndex() })
}

export function isSameISOWeek(dateLeft: Date | string | number, dateRight: Date | string | number): boolean {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _isSameISOWeek(dateLeft, dateRight)
}

export function isSameMonth(dateLeft: Date | string | number, dateRight: Date | string | number): boolean {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _isSameMonth(dateLeft, dateRight)
}

export function isSameYear(dateLeft: Date | string | number, dateRight: Date | string | number): boolean {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _isSameYear(dateLeft, dateRight)
}

export function isEqual(dateLeft: Date | string | number, dateRight: Date | string | number): boolean {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _isEqual(dateLeft, dateRight)
}

export function isToday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isToday(date)
}

export function isTomorrow(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isTomorrow(date)
}

export function isYesterday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isYesterday(date)
}

export function isValid(date: Date | string): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isValid(date)
}

export function isWeekend(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isWeekend(date)
}

export function isSunday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isSunday(date)
}

export function isMonday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isMonday(date)
}

export function isTuesday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isTuesday(date)
}

export function isWednesday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isWednesday(date)
}

export function isThursday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isThursday(date)
}

export function isFriday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isFriday(date)
}

export function isSaturday(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isSaturday(date)
}

export function isStartOfMonth(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isFirstDayOfMonth(date)
}

export function isWithinRange(
  date: Date | string | number,
  dateLeft: Date | number | string,
  dateRight: Date | number | string
): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _isWithinInterval(date, { start: dateLeft, end: dateRight })
}

export function isBefore(date: Date | string | number, dateToCompare: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (typeof dateToCompare === 'string') {
    dateToCompare = new Date(dateToCompare)
  }
  return _isBefore(date, dateToCompare)
}

export function isAfter(date: Date | string | number, dateToCompare: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (typeof dateToCompare === 'string') {
    dateToCompare = new Date(dateToCompare)
  }
  return _isAfter(date, dateToCompare)
}

export function isPast(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isPast(date)
}

export function differenceInDays(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInDays(dateLeft, dateRight)
}

export function differenceInWeeks(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInWeeks(dateLeft, dateRight)
}

export function differenceInMinutes(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInMinutes(dateLeft, dateRight)
}

export function isThisMonth(date: Date | string | number): boolean {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _isThisMonth(date)
}

export function differenceInMilliseconds(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInMilliseconds(dateLeft, dateRight)
}

export function differenceInCalendarDays(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInCalendarDays(dateLeft, dateRight)
}

export function differenceInSeconds(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInSeconds(dateLeft, dateRight)
}

export function differenceInHours(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInHours(dateLeft, dateRight)
}

export function differenceInMonths(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInMonths(dateLeft, dateRight)
}

export function differenceInYears(dateLeft: Date | string | number, dateRight: Date | string | number): number {
  if (typeof dateLeft === 'string') {
    dateLeft = new Date(dateLeft)
  }

  if (typeof dateRight === 'string') {
    dateRight = new Date(dateRight)
  }

  return _differenceInYears(dateLeft, dateRight)
}

export function setHours(date: Date | string | number, hours: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _setHours(date, hours)
}

export function setDay(date: Date | string | number, days: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _setDay(date, days, { weekStartsOn: startOfWeekIndex() })
}

export function setMonth(date: Date | string | number, month: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _setMonth(date, month)
}

export function setISOWeek(date: Date | string | number, weeks: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _setISOWeek(date, weeks)
}

export function setQuarter(date: Date | string | number, quarter: number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _setQuarter(date, quarter)
}

export function getQuarter(date: Date | string | number): number {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _getQuarter(date)
}

export function getHours(date: Date | string | number): number {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _getHours(date)
}

export function getMonth(date: Date | string | number): number {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _getMonth(date)
}

export function getYear(date: Date | string | number): number {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _getYear(date)
}

export function getISODay(date: Date | string | number): number {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _getISODay(date)
}

export function getISOWeek(date: Date | string | number): number {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _getISOWeek(date)
}

export function startOfDay(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _startOfDay(date)
}

export function startOfToday(): Date {
  return _startOfToday()
}

export function startOfWeek(
  date: Date | string | number,
  options?: {
    locale?: Locale
    weekStartsOn?: number
  }
): Date {
  const dateFnsOptions = options
    ? getDateFnsOptions(options.locale, options.weekStartsOn)
    : getDateFnsOptions(null, startOfWeekIndex())

  if (typeof date === 'string') {
    date = new Date(date)
  }
  return _startOfWeek(date, dateFnsOptions)
}

export function startOfMonth(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _startOfMonth(date)
}

export function startOfYear(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _startOfYear(date)
}

export function lastDayOfMonth(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _lastDayOfMonth(date)
}

export function endOfDay(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _endOfDay(date)
}

export function endOfToday(): Date {
  return _endOfToday()
}

export function endOfTomorrow(): Date {
  return _endOfTomorrow()
}

export function endOfYesterday(): Date {
  return _endOfYesterday()
}

export function endOfMonth(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _endOfMonth(date)
}

export function endOfQuarter(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _endOfQuarter(date)
}

export function endOfYear(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _endOfYear(date)
}

export function endOfWeek(date: Date | string | number, weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _endOfWeek(date, { weekStartsOn: weekStartsOn !== undefined ? weekStartsOn : startOfWeekIndex() })
}

export function startOfQuarter(date: Date | string | number): Date {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return _startOfQuarter(date)
}

/**
 * Transform locale and startOn into the options object that dateFns library expects
 * @param {Locale} locale
 * @param {number} startsOn
 * @returns {{locale?: Locale; weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6}}
 */
function getDateFnsOptions(
  locale?: Locale,
  startsOn?: number
): {
  locale?: Locale
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
} {
  let weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6

  if (startsOn) {
    weekStartsOn = (![0, 1, 2, 3, 4, 5, 6].includes(startsOn) ? startOfWeekIndex() : startsOn) as
      | 0
      | 1
      | 2
      | 3
      | 4
      | 5
      | 6
  }

  return { locale, weekStartsOn }
}

/**
 * Helper function to return the index of the start of week based on locale
 */
export function startOfWeekIndex(): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
  return getWeekStartByLocale(navigator.language)
}

/**
 * Helper function to determine if is a valid date
 */
export function isDate(date: Date): boolean {
  return _isDate(date) && date instanceof Date && !isNaN(date?.getDate?.())
}

export function areIntervalsOverlapping(intervalLeft: Interval, intervalRight: Interval): boolean {
  return _areIntervalsOverlapping(intervalLeft, intervalRight)
}
