import { User } from '@awork/features/user/models/user.model'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { Project } from '@awork/features/project/models/project.model'
import replace from '@awork/_shared/functions/replace'
import { UserQuery } from '@awork/features/user/state/user.query'
import { ValidActivityLogEntities } from '@awork/_shared/services/activity-log-service/types'

export interface IMentions {
  description?: string
  users: User[]
  everyUserHadPermissions?: boolean
}

export interface Reaction {
  userId: string
  emoji: string
}

export interface GroupedReaction {
  userIds: string[]
  emoji: string
}

/**
 * The model type for comment
 */
export interface IComment {
  id?: string
  entityId: string
  entityType?: 'tasks' | 'projects'
  attachments?: FileUpload[]
  hasAttachments?: boolean
  createdOn?: Date
  createdBy?: string
  updatedOn?: Date
  updatedBy?: string
  user?: User
  userId?: string
  message: string
  formattedMessage?: string
  plainFormattedMessage?: string
  mentions?: IMentions
  previews?: string[]
  temporaryFiles?
  editMessage?: string
  mode?: 'view' | 'edit'
  reactions?: Reaction[]
  isGrouped?: boolean
}

/**
 * Comment model
 */
export class Comment implements IComment {
  id?: string
  entityId: string
  entityType?: 'tasks' | 'projects'
  attachments?: FileUpload[]
  hasAttachments?: boolean
  createdOn?: Date
  createdBy?: string
  updatedOn?: Date
  updatedBy?: string
  user?: User
  userId?: string
  message: string
  formattedMessage?: string
  plainFormattedMessage?: string
  mentions?: IMentions
  previews?: string[]
  temporaryFiles?
  editMessage?: string
  mode?: 'view' | 'edit'
  project?: {
    id: string
    name: string
  }
  reactions?: Reaction[]
  isGrouped?: boolean

  constructor(comment: IComment) {
    Object.assign(this, comment)
    if (!this.editMessage) {
      this.editMessage = this.message
    }
    if (!this.mode) {
      this.mode = 'view'
    }
    if (!this.attachments) {
      this.attachments = []

      if (this.user) {
        this.user = new User(this.user, 'Comment')
      }
    }
  }

  get commentMessageMaxLength(): number {
    return 65000
  }

  get messageHTML(): string {
    if (this.formattedMessage) {
      return this.formattedMessage.replace(/(?:\r\n|\r|\n)/g, '<br>')
    } else if (this.plainFormattedMessage) {
      return this.plainFormattedMessage.replace(/(?:\r\n|\r|\n)/g, '<br>')
    } else if (this.message) {
      return this.message.replace(/(?:\r\n|\r|\n)/g, '<br>')
    } else {
      return ''
    }
  }

  get name(): string {
    return q.translations.common.comment
  }

  /**
   * Updates the comment's reaction according to the operation
   * @param {string} reaction
   * @param {"add" | "remove"} operation
   * @param {User} currentUser
   */
  updateReactions(reaction: string, operation: 'add' | 'remove', currentUser: User): void {
    switch (operation) {
      case 'add':
        this.reactions = this.reactions || []

        const existingReaction = this.reactions.find(r => r.userId === currentUser.id && r.emoji === reaction)

        if (!existingReaction) {
          this.reactions.push({
            emoji: reaction,
            userId: currentUser.id
          })
        }
        break

      case 'remove':
        this.reactions = this.reactions.filter(r => r.userId !== currentUser.id || r.emoji !== reaction)
    }
  }

  /**
   * Generates the reaction tooltip containing the user names
   * @param {GroupedReaction} groupedReaction
   * @param {ValidActivityLogEntities} entity
   * @param {boolean} includeEmoji - True to include reaction emoji into the string
   * @returns {string}
   */
  getReactionTooltip(groupedReaction: GroupedReaction, entity: ValidActivityLogEntities, includeEmoji = true): string {
    if (!UserQuery.instance) {
      return null
    }

    const currentUser = UserQuery.instance.getCurrentUser()
    const users = UserQuery.instance.getUsersByIds(groupedReaction.userIds, true)
    let notFound = 0

    // If not all users are in the store, look for them in the project members
    if (users.length < groupedReaction.userIds.length && entity) {
      const project = entity instanceof Project ? entity : entity.project

      groupedReaction.userIds
        .filter(userId => !users.some(user => user.id === userId))
        .forEach(userId => {
          const projectMember = project.members?.find(member => member.userId === userId)

          if (projectMember) {
            users.push(projectMember.user)
          } else {
            notFound++
          }
        })
    }

    const currentUserIndex = users.findIndex(user => user.id === currentUser.id)

    // Put the current user at last position
    if (currentUserIndex >= 0) {
      users.push(
        users.splice(
          users.findIndex(user => user.id === currentUser.id),
          1
        )[0]
      )
    }

    const userNames: string[] = []

    // Show the users not found as 'n users reacted with..'
    if (notFound > 0) {
      const userTranslation = notFound > 1 ? q.translations.entities.users : q.translations.entities.user
      userNames.push(`${notFound} ${userTranslation.toLowerCase()}`)
    }

    users.forEach(user => {
      if (user.id === currentUser.id) {
        const you = q.translations.Comment.you
        userNames.push(userNames.length >= 1 ? you.toLowerCase() : you)
      } else {
        userNames.push(user.fullName)
      }
    })

    let userNamesString: string

    // Generate the users string as: User 1, User 2 and User 3...
    const userCount = userNames.length
    if (userNames.length === 1) {
      userNamesString = userNames[0]
    } else {
      const lastUser = userNames.pop()
      userNamesString = `${userNames.join(', ')} ${q.translations.common.and} ${lastUser}`
    }

    if (!includeEmoji) {
      return userNamesString
    }

    const onlyOneUserReacted = userCount === 1
    if (!onlyOneUserReacted) {
      return replace(q.translations.Comment.usersReactedWith, {
        users: userNamesString,
        reaction: groupedReaction.emoji
      })
    }

    const onlyUserIsCurrentUser = users[0]?.id === currentUser.id
    if (onlyUserIsCurrentUser) {
      return replace(q.translations.Comment.youReactedWith, {
        user: userNamesString,
        reaction: groupedReaction.emoji
      })
    }

    return replace(q.translations.Comment.userReactedWith, {
      user: userNamesString,
      reaction: groupedReaction.emoji
    })
  }

  /**
   * Groups the reactions by emoji
   */
  getGroupedReactions(): GroupedReaction[] {
    const groupedReactions: GroupedReaction[] = []

    if (!this.reactions) {
      return groupedReactions
    }

    this.reactions.forEach(reaction => {
      const groupedReaction = groupedReactions.find(r => r.emoji === reaction.emoji)

      if (groupedReaction) {
        groupedReaction.userIds.push(reaction.userId)
      } else {
        groupedReactions.push({
          emoji: reaction.emoji,
          userIds: [reaction.userId]
        })
      }
    })

    return groupedReactions
  }
}
