<!-- Default white tooltip -->
<div
  *ngIf="!action"
  class="tooltip"
  [@appear]
  [style.position]="fixed ? 'fixed' : 'absolute'"
  [style.width.px]="width"
  [ngStyle]="{ top: top, left: left }">
  <i *ngIf="icon" class="aw-2-icn aw-2-icn--s aw-2-mr--xs" [ngClass]="'aw-2-txt--' + iconColor">
    {{icon}}
  </i>
  <p class="tooltip__content" [innerHTML]="content"></p>
</div>

<!-- Black action tool tip -->
<div
  *ngIf="action"
  #actionTip
  class="tooltip tooltip--colored"
  [class.tooltip--keys]="firstKey"
  [@appearTop]
  [style.position]="fixed ? 'fixed' : 'absolute'"
  [ngStyle]="{ top: top, left: left }">

  <!-- Callout -->
  <div
    class="callout"
    [class.from-top]="fromTop"
    [style.left]="'calc(50% - ' + callOutX + ')'"
  ></div>

  <!-- Content -->
  <p class="tooltip__content" [innerHTML]="content"></p>

  <!-- Keys -->
  <aw-hotkey-info
    *ngIf="firstKey"
    class="tooltip__keys"
    [firstKey]="firstKey"
    [secondKey]="secondKey"
    [thirdKey]="thirdKey"
  />
</div>
