import { Size } from '@awork/_shared/types/size'

export type AvatarSize = Size.xs | Size.s | Size.m | Size.l | Size.xl

export const AvatarPixelSizes = {
  [Size.xs]: 16,
  [Size.s]: 24,
  [Size.m]: 32,
  [Size.l]: 40,
  [Size.xl]: 80
}

export const SubAvatarPixelSizes = {
  [Size.xs]: 6,
  [Size.s]: 12,
  [Size.m]: 18,
  [Size.l]: 20,
  [Size.xl]: 40
}
