import { animate, style, transition, trigger } from '@angular/animations'
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core'
import { ModalService } from '../../../services/modal-service/modal.service'
import { PrintService } from '../../../services/print-service/print.service'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { MainSize, Size } from '@awork/_shared/types/size'
import { WithGlobals } from '../../../classes/with-globals'
import { AsyncPipe, NgClass, NgIf } from '@angular/common'
import { CloseButtonComponent } from '../../icon-buttons/close-button/close-button.component'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'
import { Variant } from './types'

type ModalSize = MainSize

@Component({
  selector: 'aw-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  animations: [
    trigger('popUp', [
      transition(':leave', [
        style({ transform: 'scale(1)' }),
        animate('.25s ease-in', style({ transform: 'scale(.4)' }))
      ]),
      transition(':enter', [
        style({ transform: 'scale(0.4)' }),
        animate('.25s cubic-bezier(0, 1, .4, 1.05)', style({ transform: 'scale(1)' }))
      ])
    ])
  ],
  imports: [NgIf, NgClass, TooltipDirective, CloseButtonComponent, AsyncPipe]
})
export class ModalComponent extends WithGlobals {
  @Input() variant: Variant = Variant.Default
  @Input() title: string
  @Input() titleSize: Size.m | Size.l = Size.m
  @Input() size: ModalSize = Size.m
  @Input() showCloseButton = true
  @Input() showHeader = true
  @Input() autoHide = true
  @Input() preventScrolling: boolean
  @Input() closeOnOverlayClick = false
  @Input() width: number
  @Input() zIndex: number = 4000
  @Input() flexBody: boolean

  @Output() popped: EventEmitter<void> = new EventEmitter<void>()
  @Output() hiding: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('modal') modal: ElementRef<HTMLElement>

  protected visible = false
  componentName: string

  readonly sizes = Size
  readonly variants = Variant

  constructor(
    private modalService: ModalService,
    public elementRef: ElementRef,
    public printService: PrintService,
    public browserService: BrowserService,
    private cdr: ChangeDetectorRef
  ) {
    super()
  }

  /**
   * Returns the current scroll position of this modal
   */
  public getScrollPosition(): number {
    return this.modal ? this.modal.nativeElement.scrollTop : 0
  }

  /**
   * Sets the current scroll position of this modal
   * @param {number} position
   *
   */
  public setScrollPosition(position: number): void {
    if (this.modal) {
      this.modal.nativeElement.scrollTop = position
    }
  }

  /**
   * Scrolls to the top of with a smooth behavior
   */
  public scrollToTop(): void {
    if (this.modal) {
      this.modal.nativeElement.scroll({ top: 0, left: 0, behavior: 'smooth' })
    }
  }

  /**
   * Helper to set the visibility of the modal from outside the component
   * Necessary so that it can trigger change detection
   * @param {boolean} visible
   */
  setVisibility(visible: boolean): void {
    this.visible = visible

    this.cdr.markForCheck()
  }

  /**
   * Returns whether the modal is visible or not
   * @returns {boolean}
   */
  isVisible(): boolean {
    return this.visible
  }

  /**
   * Shows the modal
   * @param {string} componentName - used to check if the component already has a opened modal
   */
  public show(componentName?: string): void {
    this.componentName = componentName

    if (this.modalService.modals?.length > 0) {
      const lastModal = this.modalService.modals[this.modalService.modals.length - 1]
      if (lastModal.componentName && componentName && lastModal.componentName === componentName) {
        return void 0
      }
    }

    this.visible = true
    this.modalService.addModal(this)
  }

  /**
   * Hides the modal
   * viaModalComponent is necessary to avoid loops of the hiding event and the
   * hide event handler in the component using the modal
   * @param {boolean} viaModalComponent
   */
  public hide(viaModalComponent = false): void {
    if (this.visible) {
      this.visible = false
      this.modalService.removeModal(this)

      if (viaModalComponent) {
        this.hiding.emit()
      }
    }
  }

  animationDone() {
    this.popped.emit()
  }

  @HostListener('mousedown', ['$event'])
  onMouseUp(mouseEvent: MouseEvent): void {
    if (this.closeOnOverlayClick && !this.clickedOnScrollbar(mouseEvent.x)) {
      const isOutsideModalContent = (mouseEvent.target as HTMLElement).classList.contains('modal')
      if (isOutsideModalContent) {
        this.hide(true)
      }
    }
  }

  /**
   * Determines if the user clicked on the scrollbar
   * @param {number} mouseX
   * @returns {boolean}
   * @private
   */
  private clickedOnScrollbar(mouseX: number): boolean {
    return this.modal.nativeElement.clientWidth <= mouseX
  }
}
