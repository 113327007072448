import { Criterion } from './types'
import { inject } from '@angular/core'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'

/**
 * Returns true if the current workspace id is included in the list of allowed ids
 * @param {string[]} allowedWorkspaceIds
 * @returns {boolean}
 */
export const isWorkspaceAllowedCriterion =
  (allowedWorkspaceIds: string[]): Criterion =>
  () => {
    const workspaceQuery = inject(WorkspaceQuery)
    const currentWorkspace = workspaceQuery.getCurrentWorkspace()

    return allowedWorkspaceIds.includes(currentWorkspace?.id)
  }
