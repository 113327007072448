export enum FieldSize {
  MainEntityName = 1000,
  SubEntityName = 255,
  Description = 25000,
  Color = 14,
  TechnicalSettings = Number.MAX_SAFE_INTEGER,
  TechnicalKey = 25,
  EntityKey = 50,
  UserProvidedValueShort = 100,
  UserProvidedValueLong = 1000,
  UserProvidedIndexedValue = 750
}
