interface OneDriveFilePermissionLink {
  type: string
  webUrl: string
}

interface OneDriveFilePermission {
  '@odata.context': string
  '@odata.type': string
  link: OneDriveFilePermissionLink
  shareId: string
}

interface SharePointFilePermission {
  '@odata.context': string
  '@odata.type': string
  link: OneDriveFilePermissionLink
}

export interface OneDriveFile {
  '@microsoft.graph.downloadUrl': string
  'thumbnails@odata.context'?: string
  id: string
  name: string
  size: number
  webUrl: string
  permissions: OneDriveFilePermission[] | SharePointFilePermission[]
}

export interface OneDriveResponse {
  value: OneDriveFile[]
  webUrl: string | null
}

export interface OneDriveOpenOptions {
  clientId: string
  action: 'download' | 'share' | 'query'
  multiSelect: boolean
  advanced: { redirectUri: string }
  success: (result: OneDriveResponse) => void
  cancel: () => void
  error: (e: any) => void
}

export interface OneDrive {
  open(options: OneDriveOpenOptions)
}

export interface Gapi {
  load: (type: string, options: Object) => void
}

export interface GapiAuth {
  authorize: (options: Object, callback: (result: any) => void) => void
}

export interface GooglePickerDoc {
  description: string
  driveSuccess: boolean
  id: string
  isShared: true
  name: string
  sizeBytes: number
  mimeType: string
  type: 'document' | 'file' | 'photo' | 'video'
  url: string
}

export interface GooglePickerResponse {
  action: 'load' | 'picked' | 'cancel'
  docs: GooglePickerDoc[]
}

export interface GooglePicker {
  dispose: () => void
  PickerBuilder: () => void
  DocsUploadView: () => void
  View: (viewId: string) => void
  DocsView: (viewId: string) => void

  ViewId: { DOCS: string; DOCS_IMAGES_AND_VIDEOS: string; RECENTLY_PICKED: string; FOLDERS: string }
  Action: { PICKED: string; CANCEL: string }
  Feature: {
    NAV_HIDDEN: string
    SUPPORT_DRIVE: string
    MULTISELECT_ENABLED: string
    MINE_ONLY: string
  }
}

export enum ExternalProviders {
  google = 'google',
  oneDrive = 'onedrive'
}

export interface FileExternal {
  name: string
  description?: string
  externalProvider: ExternalProviders
  externalFileUrl: string
}
