export interface IProjectMemberBase {
  id: string
  userId: string
  firstName: string
  lastName: string
  updatedOn?: Date
}

export class ProjectMemberBase implements IProjectMemberBase {
  id: string
  userId: string
  firstName: string
  lastName: string
  updatedOn?: Date

  constructor(projectMember: Partial<ProjectMemberBase>) {
    Object.assign(this, projectMember)

    if (!this.userId) {
      this.userId = this.id
    }
  }
}
