<article
  class="toast aw-2-sh--l aw-2-p--l"
  [@toastAnimation]
  [style.top.px]="top"
  [style.zIndex]="zIndex"
  [ngClass]="colorClass"
>
  <span *ngIf="isHover || isTouchDevice" class="toast__hide-button">
    <aw-close-button
      [size]="'s'"
      (click)="onClose()"
    />
  </span>

  <section class="toast__content aw-2-p--s aw-2-pos--relative">
    <p class="aw-2-txt--bold aw-2-txt--center aw-2-txt--night ">{{ npsToastTitle }}</p>

    <!-- NPS Score -->
    <div class="toast__content__nps-score">
      <div class="toast__content__nps-score--input aw-2-mt--l aw-2-mb--l">
        <aw-badge
          *ngFor="let score of scores" 
          [size]="'l'"
          [label]="score.value.toString()"
          [clickable]="true"
          [color]="selectedScore === score.value ? 'blue' : 'steel'"
          (onClick)="onScoreChange(score.value)"
        />
      </div>

      <small class="aw-2-txt--body-tiny aw-2-txt--bold">{{ translations.score.label.negative }}</small>
      <small class="aw-2-txt--body-tiny aw-2-txt--bold aw-2-pos--float-right">{{ translations.score.label.positive }}</small>
    </div>

    <!-- Feedback -->
    <div *ngIf="selectedScore >= 0" [style.width.%]="100" [@expand]>
      <p class="aw-2-txt--bold aw-2-txt--center aw-2-txt--night aw-2-mb--xl aw-2-mt--xl">{{ scoreFeedbackTitle }}</p>

      <aw-multiline-text-field
        [minRows]="3"
        [maxRows]="3"
        [placeholder]="scoreFeedbackPlaceholder"
        (change)="onFeedbackInput($event.target.value)"
      />

      <div class="aw-2-txt--right aw-2-mt--l">
        <aw-button
          [size]="'m'"
          [type]="'tertiary'"
          [text]="q.translations.common.cancel"
          (click)="onClose()"
        />

        <aw-button
          class="aw-2-ml--s"
          [size]="'m'"
          [type]="'primary'"
          [text]="q.translations.common.send"
          (click)="onSendFeedbackClick()"
        />
      </div>
    </div>
  </section>
</article>
