import { Injectable } from '@angular/core'
import { SignalStore } from '@awork/core/state/signal-store/signalStore'
import { EnumKeyByValue } from '@awork/_shared/types/enum-key-by-value'

/**
 * Feature flag key definitions
 */
export enum FeatureFlag {
  connect = 'connect',
  aiTextCompletionFeatures = 'ai-text-completion-features',
  datadogRum = 'datadog-rum',
  datadogReplay = 'datadog-replay',
  specialEvent = 'special-event',
  wmtTaskListWidget = 'wmt-task-list-widget',
  wmtProjectSorting = 'wmt-project-sorting',
  customFields = 'custom-fields',
  wmtSplitViewDrag = 'wmt-split-view-drag',
  taskTemplateTags = 'task-template-tags',
  wmtProjectProgress = 'wmt-project-progress',
  companyTrackedPlanned = 'tt-company-tracked-planned',
  wmtProjectMemberInColumn = 'wmt-project-members-in-column',
  wmtMoveProjectDates = 'wmt-move-project-dates',
  wmtAbsenceWidgetFilter = 'wmt-absence-widget-filter',
  wmtMoveTaskToProject = 'wmt-copy-move-tasks-to-project',
  wmtProgressBarsInProjectList = 'wmt-progress-bars-in-project-list',
  wmtTypeOfWork = 'wmt-type-of-work',
  wmtSlashCommandsRanking = 'wmt-slash-commands-ranking',
  wmtShowDoneInTimeline = 'wmt-show-done-in-timeline',
  wmtPlannerRange = 'wmt-planner-range',
  growthQuickProjectConnect = 'growth-quick-project-connect'
}

/**
 * Feature flag type definition
 */
export class FeatureFlagState implements FeatureFlagDefinition {
  aiTextCompletionFeatures: boolean
  connect: boolean
  datadogRum: number
  datadogReplay: number
  specialEvent: { startDate: Date; endDate: Date }
  wmtTaskListWidget: boolean
  wmtProjectSorting: boolean
  wmtSplitViewDrag: boolean
  customFields: boolean
  taskTemplateTags: boolean
  wmtProjectProgress: boolean
  companyTrackedPlanned: boolean
  wmtProjectMemberInColumn: boolean
  wmtMoveProjectDates: boolean
  wmtAbsenceWidgetFilter: boolean
  wmtMoveTaskToProject: boolean
  wmtProgressBarsInProjectList: boolean
  wmtTypeOfWork: boolean
  wmtSlashCommandsRanking: boolean
  wmtShowDoneInTimeline: boolean
  wmtPlannerRange: boolean
  growthQuickProjectConnect: boolean
}

export type LaunchDarklyFeatureFlags = { [value in FeatureFlag]: boolean | string | number | Object }

export type FeatureFlagKey = keyof typeof FeatureFlag
export type FeatureFlagTypeByKey<K extends FeatureFlagKey> = FeatureFlagState[K]
export type FeatureFlagDefinition = { [key in FeatureFlagKey]: FeatureFlagTypeByKey<key> }

export type FeatureFlagType<K extends FeatureFlag> = FeatureFlagState[EnumKeyByValue<typeof FeatureFlag, K>]

function createInitialState(): FeatureFlagState {
  return Object.keys(FeatureFlag).reduce((acc, key) => {
    acc[key] = false
    return acc
  }, {} as FeatureFlagState)
}

@Injectable({ providedIn: 'root' })
export class FeatureFlagStore extends SignalStore<FeatureFlagState> {
  constructor() {
    super({ name: 'featureFlag', initialState: createInitialState() })
  }

  /**
   * Updates the feature flags
   * @param {FeatureFlagState} flags
   */
  updateFlags(flags: LaunchDarklyFeatureFlags): void {
    const normalizedFlags = this.mapFeatureFlags(flags)
    this.update(normalizedFlags)
    this.setLoading(false)
  }

  /**
   * Maps the LaunchDarkly feature flags to the FeatureFlagState.
   * If a flag is not found in the FeatureFlag enum, it is ignored.
   * @param {FeatureFlagState} flags
   * @returns {FeatureFlagState}
   */
  private mapFeatureFlags(flags: LaunchDarklyFeatureFlags): FeatureFlagState {
    return Object.keys(flags).reduce((acc, key) => {
      const enumKey = Object.keys(FeatureFlag)[Object.values(FeatureFlag).indexOf(key as FeatureFlag)]

      if (enumKey !== undefined) {
        acc[enumKey] = flags[key]
      }
      return acc
    }, {} as FeatureFlagState)
  }
}
