export enum Color {
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
  Purple = 'purple',
  Yellow = 'yellow',
  Orange = 'orange',
  Cyan = 'cyan',
  Indigo = 'indigo',
  White = 'white',
  AlwaysWhite = 'always-white',
  Pink = 'pink',
  LightPink = 'lightPink',
  DeepPink = 'deepPink',
  DeepYellow = 'deepYellow',
  DeepOrange = 'deepOrange',
  LightGreen = 'lightGreen',
  DeepBlue = 'deepBlue',
  RoyalBlue = 'royalBlue',
  LightBlue = 'lightBlue',
  Asphalt = 'asphalt',
  Night = 'night',
  Slate = 'slate',
  Steel = 'steel',
  LightSteel = 'light-steel',
  Dust = 'dust',
  Fog = 'fog',
  Sky = 'sky',
  Ice = 'ice',
  Smoke = 'smoke',
  Teal = 'teal'
}

export enum DeprecatedColor {
  Coral = 'coral',
  Arctic = 'arctic',
  Azure = 'azure',
  Violet = 'violet',
  Pink = 'pink'
}

export type IconButtonColor = Color.Blue | Color.Yellow | Color.Red | Color.Green | Color.Purple
export type MainColor = Color.Red | Color.Green | Color.Blue | Color.Purple | Color.Yellow
export type SecondaryColor = Color.Orange | Color.Cyan | Color.Indigo
export type GreyColor =
  | Color.White
  | Color.Asphalt
  | Color.Night
  | Color.Slate
  | Color.Steel
  | Color.LightSteel
  | Color.Dust
  | Color.Fog
  | Color.Sky
  | Color.Ice
  | Color.Smoke

export type BadgeColor =
  | Color.Steel
  | Color.Purple
  | Color.Red
  | Color.Blue
  | Color.Yellow
  | Color.Green
  | Color.Orange
  | Color.Cyan
  | Color.Indigo
