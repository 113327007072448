import {
  throwError,
  of,
  Observable,
  concat,
  timeoutWith,
  finalize,
  retryWhen,
  mergeMap,
  delay,
  take,
  switchMap,
  forkJoin,
  map,
  timer
} from 'rxjs'
import {
  HttpClient,
  HttpParams,
  HttpResponse,
  HttpParameterCodec,
  HttpHeaders,
  HttpContext
} from '@angular/common/http'
import { Output, EventEmitter, Injectable } from '@angular/core'
import isTest from '@awork/_shared/functions/is-test'

interface RetryConfig {
  maxRetryAttempts?: number
  scalingDuration?: number
  excludedStatusCodes?: number[]
}

/**
 * A custom encoder used for HtpParams to prevent bad encoding of the '+' sign
 */
class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key)
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value)
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key)
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value)
  }
}

export interface ErrorResponse {
  code: string
  description: string | string[]
}

export interface MultiStatusResponse {
  badRequest: { ids: string[] }
  notFound: { ids: string[] }
  notAuthorized: { ids: string[] }
  ok: { ids: string[] }
}

export interface Options {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[]
      }
  context?: HttpContext
  observe?: 'body' | 'events' | 'response'
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>
      }
  reportProgress?: boolean
  responseType?: 'json' | 'text' | 'blob' | 'arraybuffer'
  withCredentials?: boolean
  timeout?: number
}

/**
 * Wrapper of the HttpClient to modify the requests globally
 */
@Injectable({ providedIn: 'root' })
export class ApiClient extends HttpClient {
  private req: Observable<any>
  private url: string
  private retries = 3
  private delay = 1000
  private timeout = 30000

  @Output() responseStart: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() responseEnd: EventEmitter<boolean> = new EventEmitter<boolean>()

  static getQueryParams(queryParams: QueryParams): HttpParams {
    let params = new HttpParams({ encoder: new CustomEncoder() })

    if (queryParams) {
      if (queryParams.page) {
        params = params.append('page', queryParams.page.toString())
      }
      if (queryParams.pageSize) {
        params = params.append('pageSize', queryParams.pageSize.toString())
      }
      // now supports multi clauses: 'Rating asc,Category/Name desc'
      if (queryParams.orderBy) {
        params = params.append('orderby', queryParams.orderBy.replace(/(?:\r\n|\r|\n)/g, ' '))
      }
      if (queryParams.filterBy) {
        // encode ampersand `&`
        queryParams.filterBy = queryParams.filterBy.replace(/&/g, '%26')
        params = params.append('filterby', queryParams.filterBy.replace(/(?:\r\n|\r|\n)/g, ' '))
      }
      // set this param to true to get the count of the query
      if (queryParams.count) {
        params = params.append('count', queryParams.count ? 'true' : 'false')
      }
    }

    return params
  }

  /**
   * Sends a GET request to the API
   * @param {string} url
   * @param {Object} options
   * @return {Observable<T>}-
   */
  get<T>(url: string, options?: Options): Observable<T> {
    this.responseStart.emit(true)

    this.req = super.get<T>(url, options as Object).pipe(
      finalize(() => {
        this.responseEnd.emit(true)
      })
    )

    this.url = url

    // Modifiers
    this.timeoutPolicy(options?.timeout)
    this.retryPolicy()

    return this.req
  }

  /**
   * Send GET requests to the API to fetch all entities
   * If multiple pages are available, parallel calls are made to fetch them all
   * @param {string} url
   * @param {Options} options
   * @return {Observable<T>}
   */
  getAll<T>(url: string, options?: Options): Observable<T> {
    return this.get<HttpResponse<T[]>>(url, { ...options, observe: 'response' }).pipe(
      switchMap(response => {
        const entities = response.body || []

        const totalItems = Number(response.headers.get('aw-totalitems'))
        const pageSize: number = parseInt((<HttpParams>options.params).get('pageSize'))
        const totalPages = Math.ceil(totalItems / pageSize)

        if (totalPages > 1 && (<HttpParams>options.params).get('count') !== 'true') {
          const requests: Observable<HttpResponse<T[]>>[] = []

          for (let i = 2; i <= totalPages; i++) {
            options.params = (<HttpParams>options.params).set('page', i)
            requests.push(this.get<HttpResponse<T[]>>(url, { ...options, observe: 'response' }))
          }

          return forkJoin(requests).pipe(
            map(results => {
              results.forEach(result => {
                entities.push(...result.body)
              })

              return options.observe === 'response' ? new HttpResponse({ ...response, body: entities }) : entities
            })
          )
        } else {
          return of(options.observe === 'response' ? new HttpResponse({ ...response, body: entities }) : entities)
        }
      })
    ) as unknown as Observable<T>
  }

  /**
   * Sends a POST request to the API
   * @param {string} url
   * @param body
   * @param {Object} options
   * @return {Observable<T>}
   */
  post<T>(url: string, body: any, options?: Options): Observable<T> {
    this.req = super.post<T>(url, body, options as Object)

    this.url = url

    // Modifiers
    this.timeoutPolicy(options?.timeout)
    // this.retryPolicy()

    return this.req
  }

  /**
   * Sends a PUT request to the API
   * @param {string} url
   * @param body
   * @param {Object} options
   * @return {Observable<T>}
   */
  put<T>(url: string, body: any, options?: Options): Observable<T> {
    this.req = super.put<T>(url, body, options as Object)

    this.url = url

    // Modifiers
    this.timeoutPolicy(options?.timeout)
    // this.retryPolicy()

    return this.req
  }

  /**
   * Sends a DELETE request to the API
   * @param {string} url
   * @param {Object} options
   * @return {Observable<T>}
   */
  delete<T>(url: string, options?: Options): Observable<T> {
    this.req = super.delete<T>(url, options as Object)

    this.url = url

    // Modifiers
    this.timeoutPolicy(options?.timeout)
    // this.retryPolicy()

    return this.req
  }

  /**
   * Defines the retry policy of the requests sent to the API
   */
  private retryPolicy(): void {
    // Exclude app version checks for the retry policy
    if (!this.url.includes('/v1/versions') && !isTest()) {
      this.req = this.req.pipe(
        retryWhen(errors => {
          return concat(
            errors.pipe(
              mergeMap((error: any) => {
                if (error.status === 503) {
                  // retry if the server timed out (HTTP ERROR 503)
                  return of(error.status).pipe(delay(this.delay)) // Wait before retry
                }
                return throwError(error) // If not timeout, don't do anything
              }),
              take(this.retries) // Set the number of retries
            ),
            of(this.showError(`Sorry, there was an timeout (after ${this.retries} retries)`)),
            throwError(new HttpResponse({ status: 503, statusText: 'Server timeout' }))
          )
        })
      )
    }
  }

  /**
   * Defines the client timeout policy of the request sent to the API
   */
  private timeoutPolicy(timeout?: number): void {
    // Exclude files endpoints for the timeout policy
    if (this.url && !this.url.includes('/files') && !this.url.includes('/invitations') && !isTest()) {
      this.req = this.req.pipe(
        timeoutWith(
          timeout ? timeout : this.timeout,
          throwError(new HttpResponse({ status: 503, statusText: 'Client timeout' }))
        )
      )
    }
  }

  private showError(message: string): void {
    console.log(message)
  }

  /**
   * Retries a failed observable sequence based on the provided configuration.
   * @returns {({maxRetryAttempts, scalingDuration, excludedStatusCodes}?: RetryConfig) => (attempts: Observable<any>) => Observable<0>}
   */
  genericRetryStrategy =
    ({ maxRetryAttempts = 3, scalingDuration = 1000, excludedStatusCodes = [] }: RetryConfig = {}) =>
    (attempts: Observable<any>) => {
      return attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1
          // if maximum number of retries have been met
          // or response is a status code we don't wish to retry, throw error
          if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === error.status)) {
            return throwError(error)
          }

          // retry after 1s, 2s, etc...
          return timer(retryAttempt * scalingDuration)
        })
      )
    }
}

export interface QueryParams {
  page?: number
  pageSize?: number
  orderBy?: string
  filterBy?: string
  count?: boolean
}
