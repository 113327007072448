import { SubscriptionState, SubscriptionStore } from '@awork/_shared/state/subscription.store'
import { Injectable } from '@angular/core'
import { map, Observable } from 'rxjs'
import {
  businessFeatureRestrictions,
  CANCELLED,
  connectFeatureRestrictions,
  FeatureRestrictions,
  IN_TRIAL,
  Plan,
  PlanTier,
  QSubscription,
  teamFeatureRestrictions
} from '@awork/_shared/models/subscription.model'
import isTest from '@awork/_shared/functions/is-test'
import { SignalQuery } from '@awork/core/state/signal-store/signalQuery'

@Injectable({ providedIn: 'root' })
export class SubscriptionQuery extends SignalQuery<SubscriptionState> {
  constructor(protected store: SubscriptionStore) {
    super(store)
  }

  selectSubscription(): Observable<QSubscription> {
    return this.select().pipe(map(state => new QSubscription(state)))
  }

  getSubscription(): QSubscription {
    return new QSubscription(this.getValue())
  }

  /**
   * Determines if the current plan is paid
   * @returns {boolean}
   */
  isPaidPlan(): boolean {
    const plan = this.getSubscription()

    if (plan) {
      return plan.isPaidPlan
    } else {
      return true
    }
  }

  /**
   * Determines if the current user has the required plan (or higher)
   * @param {string} planRequired
   * @return {boolean}
   */
  hasPlan(planRequired: string): boolean {
    const plan = this.getSubscription()

    if (plan) {
      const planId = plan.planId
      if (planId === null || planId === undefined) {
        return false
      } else if (planId.includes(Plan.Internal)) {
        return true
      } else if (planRequired === Plan.Free) {
        return planId.includes(planRequired) || planId.includes(Plan.Standard) || planId.includes(Plan.Enterprise)
      } else if (planRequired === Plan.Standard) {
        return planId.includes(planRequired) || planId.includes(Plan.Enterprise)
      } else {
        return planId.includes(planRequired)
      }
    }

    return false
  }

  /**
   * Determines if the free premium trial is expired
   */
  isTrialExpired(): boolean {
    const plan = this.getSubscription()

    return plan.isPaidPlan && plan.trialEndDays === 0 && plan.status === CANCELLED && plan.previousStatus === IN_TRIAL
  }

  /**
   * Determines if the current plan is in trial
   */
  isInTrial(): boolean {
    const plan = this.getSubscription()

    return plan.isInTrial ?? false
  }

  /**
   * Determines if the current plan is Enterprise
   */
  isEnterprise(): boolean {
    const plan = this.getSubscription()

    return plan.planId?.includes('enterprise')
  }

  /**
   * Determines if the current plan has a feature available
   * @param awSubscriptionFeature
   */
  isFeatureAvailable(awSubscriptionFeature: FeatureRestrictions) {
    const plan = this.getSubscription()
    const subscriptionHasNoPlan = !plan?.planId

    // Prevent check in test environment if subscription is not set
    // otherwise, the subscription needs to be mocked in every test where this function is called
    if (subscriptionHasNoPlan && isTest()) {
      return true
    }

    if (subscriptionHasNoPlan) {
      return false
    }

    const planTier = QSubscription.getPlanTier(plan.planId)

    switch (planTier) {
      case PlanTier.Free:
      case PlanTier.Enterprise:
        return true
      case PlanTier.Team:
        return (
          !teamFeatureRestrictions.includes(awSubscriptionFeature) &&
          !businessFeatureRestrictions.includes(awSubscriptionFeature)
        )
      case PlanTier.Business:
        // This is a special case when the user is basic connect but are currently
        // in the business plan trial
        if (plan.isBasicConnectInTrial) {
          return !connectFeatureRestrictions.includes(awSubscriptionFeature)
        }

        return !businessFeatureRestrictions.includes(awSubscriptionFeature)
      case PlanTier.Connect:
        return !connectFeatureRestrictions.includes(awSubscriptionFeature)
    }
  }
}
