import {
  DurationFormatType,
  IEntityTimesSettings,
  IProjectViewSetting,
  ListEntity,
  VIEW_PERSISTENCE_USER_SETTINGS,
  VIEW_PERSISTENCE_VALUE_SETTINGS
} from '@awork/framework/models/view-setting.model'
import { SignalQuery } from '@awork/core/state/signal-store/signalQuery'
import { IGroupingSorting, SettingsState, SettingsStore } from '@awork/framework/state/settings.store'
import { computed, Injectable, Signal } from '@angular/core'
import { Observable, map, take } from 'rxjs'
import { NotificationUserSetting } from '@awork/framework/state/settings.store'
import { UserSetting } from '@awork/_shared/models/user-setting.model'
import {
  IFrameworkViewSetting,
  ITaskViewListsSetting,
  IProjectTaskListSetting,
  IViewPersistenceSetting
} from '@awork/framework/models/view-setting.model'
import { ValidStandardTimeReportEntityType } from '@awork/features/time-tracking/models/types'

export const DEFAULT_FRAMEWORK_VIEW_SETTINGS: IFrameworkViewSetting = {
  darkMode: 'auto',
  menuPinned: true,
  menuWidth: 280,
  menuItemsExpandedState: []
}

@Injectable({ providedIn: 'root' })
export class SettingsQuery extends SignalQuery<SettingsState> {
  constructor(protected store: SettingsStore) {
    super(store)
  }

  /**
   * Selects the notifications user settings
   */
  selectNotificationSettings(): Observable<NotificationUserSetting[]> {
    return this.select('notifications')
  }

  getNotificationSettings(): NotificationUserSetting[] {
    return this.getValue().notifications
  }

  /**
   * Get View User Settings
   */
  selectViewSetting<T extends keyof IViewPersistenceSetting>(
    viewSettingsType: T
  ): Observable<IViewPersistenceSetting[T]> {
    return this.select().pipe(
      map(settingState => {
        return settingState.viewSettings?.[viewSettingsType] || this.getViewSettingEmptyValue(viewSettingsType)
      })
    )
  }

  /**
   * Gets the view settings of a specific key
   * @param {T} viewSettingsType
   * @returns {Signal<IViewPersistenceSetting[T]>}
   */
  queryViewSetting<T extends keyof IViewPersistenceSetting>(viewSettingsType: T): Signal<IViewPersistenceSetting[T]> {
    return computed(() => {
      const settingState = this.query()
      return settingState().viewSettings?.[viewSettingsType] || this.getViewSettingEmptyValue(viewSettingsType)
    })
  }

  /**
   * Selects the project task list settings for a specific project
   * @param {string} projectId
   */
  selectProjectTaskListSetting(projectId: string): Observable<IProjectTaskListSetting> {
    return this.select('projectTaskListSettings').pipe(
      map(projectTaskListSettings => projectTaskListSettings?.find(setting => setting.projectId === projectId) || null)
    )
  }

  /**
   * Gets the entity times settings for a specific entity
   * @param {string} entityId
   * @param {ValidStandardTimeReportEntityType} entityType
   * @returns {Signal<IEntityTimesSettings>}
   */
  queryEntityTimesSettings(
    entityId: string,
    entityType: ValidStandardTimeReportEntityType
  ): Signal<IEntityTimesSettings> {
    return computed(() => {
      const settingState = this.query('entityTimesSettings')
      return (
        settingState()?.find(setting => setting.entityId === entityId && setting.entityType === entityType) ||
        ({ entityId: entityId } as IEntityTimesSettings)
      )
    })
  }

  getProjectSetting(projectId: string): IProjectViewSetting {
    const settingState = this.getValue()
    return settingState.projectSettings
      ? settingState.projectSettings.find(setting => setting.projectId === projectId)
      : null
  }

  /**
   * Selects the project settings for a specific project
   * @param {string} projectId
   */
  selectProjectSetting(projectId: string): Observable<IProjectViewSetting> {
    return this.select('projectSettings').pipe(
      map(projectSettings => projectSettings?.find(setting => setting.projectId === projectId) || null)
    )
  }

  getProjectTaskListSetting(projectId: string): IProjectTaskListSetting {
    const settingState = this.getValue()
    return settingState.projectTaskListSettings
      ? settingState.projectTaskListSettings.find(setting => setting.projectId === projectId)
      : null
  }

  /**
   * gets the user setting dynamicDurationFormat
   * @returns {DurationFormatType}
   */
  getDynamicDurationSetting(): DurationFormatType {
    if (this.getValue().viewSettings?.hasOwnProperty('dynamicDurationFormat')) {
      return this.getValue().viewSettings.dynamicDurationFormat
    }

    return null
  }

  /**
   * Gets the view settings of an specific key
   * @param {T} viewSettingsType
   * @returns {IViewPersistenceSetting[T] | undefined}
   */
  getViewSetting<T extends keyof IViewPersistenceSetting>(viewSettingsType: T): IViewPersistenceSetting[T] | undefined {
    if (this.getValue().viewSettings && this.getValue().viewSettings.hasOwnProperty(viewSettingsType)) {
      return this.getValue().viewSettings[viewSettingsType]
    }

    return this.getViewSettingEmptyValue(viewSettingsType)
  }

  /**
   * Gets the view setting empty value depending on the type
   * @param {T} viewSettingsType
   * @returns {IViewPersistenceSetting[T] | undefined}
   */
  private getViewSettingEmptyValue<T extends keyof IViewPersistenceSetting>(
    viewSettingsType: T
  ): IViewPersistenceSetting[T] | undefined {
    if (VIEW_PERSISTENCE_USER_SETTINGS.includes(viewSettingsType)) {
      return [] as IViewPersistenceSetting[T]
    } else if (VIEW_PERSISTENCE_VALUE_SETTINGS.includes(viewSettingsType)) {
      return undefined
    } else {
      return {} as IViewPersistenceSetting[T]
    }
  }

  /**
   * Select all user settings
   */
  selectUserSettings(): Observable<UserSetting> {
    return this.select('viewSettings').pipe(
      map(viewSettings => new UserSetting({ key: 'userViewPersistence', value: JSON.stringify(viewSettings) })),
      take(1)
    )
  }

  /**
   * Select all user settings
   */
  selectViewSettings(): Observable<IViewPersistenceSetting> {
    return this.select('viewSettings').pipe(
      map(viewSettings => viewSettings || {}),
      take(1)
    )
  }

  /**
   * Gets the view settings
   */
  queryViewSettings(): Signal<IViewPersistenceSetting> {
    return computed(() => {
      const viewSetting = this.query('viewSettings')
      return viewSetting() || {}
    })
  }

  /**
   * Selects the framework's view settings.
   * If there are no settings, it returns default settings values.
   * @returns {Observable<IFrameworkViewSetting>}
   */
  selectFrameworkSettings(): Observable<IFrameworkViewSetting> {
    return this.select('viewSettings').pipe(
      map(viewSettings => {
        const storedSettings = viewSettings?.framework || {}
        return { ...DEFAULT_FRAMEWORK_VIEW_SETTINGS, ...storedSettings }
      })
    )
  }

  /**
   * Gets the framework's view settings.
   * If there are no settings, it returns default settings values.
   * @returns {Signal<IFrameworkViewSetting>}
   */
  queryFrameworkSettings(): Signal<IFrameworkViewSetting> {
    return computed(() => {
      const viewSettings = this.query('viewSettings')
      const storedSettings = viewSettings().framework || {}
      return { ...DEFAULT_FRAMEWORK_VIEW_SETTINGS, ...storedSettings }
    })
  }

  /**
   * Gets the framework's view settings.
   * If there are no settings, it returns default settings values.
   * @returns {IFrameworkViewSetting}
   */
  getFrameworkSettings(): IFrameworkViewSetting {
    const storedSettings = this.getValue().viewSettings?.framework || {}
    return { ...DEFAULT_FRAMEWORK_VIEW_SETTINGS, ...storedSettings }
  }

  /**
   * Gets the visible columns for an entity list
   * @param {ListEntity} listEntity
   * @param {string} projectId
   * @returns {Signal<{smallList: string[], largeList: string[]}>}
   */
  queryListColumns(listEntity: ListEntity, projectId?: string): Signal<{ smallList: string[]; largeList: string[] }> {
    return computed(() => {
      const viewSettings = this.query('viewSettings')
      const listColumns = viewSettings()?.listColumns

      const smallList = listColumns?.smallList?.[listEntity]
      const largeList = listColumns?.largeList?.[listEntity]

      if (listEntity === 'projectTask') {
        return {
          smallList: smallList?.[projectId || 'default'],
          largeList: largeList?.[projectId || 'default']
        }
      }

      return { smallList, largeList }
    })
  }

  /**
   * Get all view settings
   * @returns {IViewPersistenceSetting}
   */
  getViewSettings(): IViewPersistenceSetting {
    return this.getValue().viewSettings || {}
  }

  /**
   * Select Task View List Settings
   * @returns {Observable<ITaskViewListsSetting[]>}
   */
  selectTaskViewListSettings(): Observable<ITaskViewListsSetting[]> {
    return this.select('taskViewListsSettings').pipe(map(taskViewListsSettings => taskViewListsSettings || null))
  }

  /**
   * Select taskListWidget Settings
   * @returns {Observable<ITaskViewListsSetting[]>}
   */
  selectTaskListWidgetSettings(): Observable<ITaskViewListsSetting[]> {
    return this.select('taskListWidgetSettings').pipe(map(taskListWidgetSettings => taskListWidgetSettings || null))
  }

  /**
   * Select Sorting Grouping Options
   * @returns {IGroupingSorting}
   */
  getSortingGroupingOptions(): IGroupingSorting {
    return this.getValue().sortingGrouping || {}
  }
}
