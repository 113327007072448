import { TaskListBase } from '@awork/features/task/models/task-list.model.base'
import { Task } from '@awork/features/task/models/task.model'

export const WITHOUT_LIST_ID = 'without-list'

/**
 * The task list in awork
 */
export class TaskList extends TaskListBase {
  isNew?: boolean
  isWithoutList?: boolean

  constructor(taskList: any) {
    super(taskList)

    this.isNew = taskList.isNew
    this.isWithoutList = taskList.id === WITHOUT_LIST_ID || this.order === 9999
  }

  static isTaskList(list: any): list is TaskList {
    return (list as TaskList)?.typeName === 'TaskList'
  }

  static getWithoutList(): TaskList {
    return new TaskList({ id: WITHOUT_LIST_ID, order: 9999 })
  }

  get type(): string {
    if (this.projectId) {
      return 'project'
    } else {
      return 'private'
    }
  }

  setOrderOfTask(tasks: Task[]): void {
    const nTasks = tasks ? tasks.length : 0

    // Set the order as the last one in the list
    // (the one before this new task that is already pushed to the array, hence `nTasks - 2`)
    if (nTasks > 1) {
      const lastTaskList = tasks[nTasks - 2].lists.find(list => list.id === this.id)

      if (lastTaskList) {
        this.orderOfTask = lastTaskList.orderOfTask + 1
      }
    } else {
      this.orderOfTask = 0
    }
  }

  /**
   * Task list name max length
   */
  get taskListNameMaxLength(): number {
    return 100
  }
}
