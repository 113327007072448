<article
  class="dd-badge"
  [class.dd-badge--hover]="clickable()"
  [class.dd-badge--disabled]="disabled()"
  [class.aw-2-pr--xs]="removable() || editable()"
  [class.monospace]="monospace()"
  [ngClass]="badgeClasses()"
  [style.maxWidth]="maxWidth()"
  (click)="onBadgeClick($event)"
>

  <!-- avatar -->
  <ng-content />

  @if (icon() && !iconRight()) {
    <i class="aw-2-icn" [ngClass]="iconSizeClass()">{{ icon() }}</i>
  }

  @if (label() && !renderAsHtml()) {
    <label class="aw-2-txt--truncate" [attr.title]="label()">{{ label() }}</label>
  }

  @if (label() && renderAsHtml()) {
    <label class="aw-2-txt--truncate" [innerHtml]="label()"></label>
  }

  @if (icon() && iconRight()) {
    <i class="aw-2-icn" [ngClass]="iconSizeClass()">{{ icon() }}</i>
  }

  <!-- actions -->
  <span *ngIf="editable() || removable()" class="aw-2-flx">
    @if (editable()) {
      <i
        class="aw-2-icn dd-badge__button"
        [ngClass]="iconSizeClass()"
        [awTooltip]="commonTranslations.edit"
        [actionTooltip]="true"
        [showDelay]="200"
        (click)="onEditClick($event)"
      >
        edit
      </i>
    }

    @if (removable()) {
      <i
        class="aw-2-icn dd-badge__button dd-badge__button--close"
        [ngClass]="iconSizeClass()"
        [awTooltip]="removeTooltip() || commonTranslations.remove"
        [actionTooltip]="true"
        [showDelay]="200"
        (click)="onRemoveClick($event)"
      >
        close
      </i>
    }
  </span>
</article>
