import {
  catchError,
  filter,
  forkJoin,
  from,
  fromEvent,
  map,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
  throwError
} from 'rxjs'
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Router } from '@angular/router'
import { effect, Injectable, OnDestroy } from '@angular/core'
import {
  apiEndpoint,
  apiProtocol,
  app,
  clientSecret,
  environment,
  isLiveMobile,
  mobileAppClientCredentials
} from '@awork/environments/environment'
import { ApiClient } from '@awork/_shared/services/api-client/ApiClient'
import { BrowserService } from '@awork/_shared/services//browser-service/browser.service'
import { User } from '@awork/features/user/models/user.model'
import { Subdomain, Workspace } from '@awork/features/workspace/models/workspace.model'
import { AccountStore } from '@awork/_shared/state/account.store'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { encrypt } from '@awork/_shared/functions/encrypt'
import { get as getCookie, remove as removeCookie, set as setCookie } from 'es-cookie'
import { addMonths } from '@awork/_shared/functions/date-fns-wrappers'
import { buildQueryString } from '@awork/_shared/functions/query-params-helper'
import { AppStore } from '@awork/core/state/app.store'
import { AppQuery } from '@awork/core/state/app.query'
import { DEFAULT_PERSIST_DELAY } from '@awork/core/state/signal-store/types'
import { UserQuery } from '@awork/features/user/state/user.query'
import { OAuthResponse } from '@awork/_shared/models/oauth.model'
import { generateGUID } from '@awork/_shared/functions/guid-generator'
import { WorkspaceStore } from '@awork/features/workspace/state/workspace.store'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { Account, AccountState } from '@awork/_shared/models/account.model'
import { ValidateAccount, ValidatedAccount } from '@awork/_shared/models/validate-account.model'
import { TrackingService } from '@awork/_shared/services/tracking-service/tracking.service'
import { authentication } from '@microsoft/teams-js'
import { AutoUnsubscribe } from '@awork/_shared/decorators/auto-unsubscribe'
import localStorageHelper from '@awork/_shared/functions/local-storage'
import { LogService } from '@awork/_shared/services/log-service/log.service'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import getHostname from '@awork/_shared/functions/get-hostname'
import { rotateCharacters } from '@awork/_shared/functions/string-operations'
import { SignalStoreService } from '@awork/core/state/signal-store/signalStore.service'
import { AuthResponse, LoginData } from '@awork/_shared/services/account-service/types'
import { LogInfo } from '../log-service/types'
import { FeatureFlagService } from '@awork/_shared/feature-flags/feature-flag.service'
import { Feature } from '@awork/_shared/feature-flags/feature-flag.features'

@AutoUnsubscribe()
@Injectable({ providedIn: 'root' })
export class AccountService implements OnDestroy {
  private readonly url: string
  private readonly hostname: string
  private redirectUrl?: string // Store the URL that is attempted to access
  private queryParam?: string
  public email: string
  public account: AccountState
  private password: string
  private loginEndpoint: string
  private refreshEndpoint: string

  private refreshingSession = false
  refreshingSession$ = new BehaviorSubject<boolean>(false)

  subscriptions: Subscription[] = []

  subdomainCookie = 'aw_sd'

  private accountFetched: boolean

  private tempSavedCredentials: {
    email: string
    password: string
  }

  constructor(
    private apiClient: ApiClient,
    private _router: Router,
    private browserService: BrowserService,
    private accountStore: AccountStore,
    private accountQuery: AccountQuery,
    private appStore: AppStore,
    private appQuery: AppQuery,
    private userQuery: UserQuery,
    private workspaceStore: WorkspaceStore,
    private workspaceQuery: WorkspaceQuery,
    private trackingService: TrackingService,
    private logService: LogService,
    private signalStoreService: SignalStoreService,
    private featureFlagService: FeatureFlagService
  ) {
    this.url = `${apiEndpoint}/accounts/`
    this.hostname = getHostname()

    // In local dev mode, get the access token instead of a cookie.
    this.loginEndpoint = isLiveMobile ? 'token' : 'cookie'
    this.refreshEndpoint = isLiveMobile ? 'token' : 'refreshcookie'

    effect(
      () => {
        const accountState = this.accountQuery.query()
        this.appStore.update({ isLoggedIn: accountState()?.email !== null && !!accountState().refreshAt })
      },
      { allowSignalWrites: true }
    )

    this.accountFetched = false

    if (this.featureFlagService.isFeatureEnabled(Feature.SkipAccountServiceUserCheck)) {
      return
    }

    // Edge case: If the user is logged in, but the current user is not set
    // then log the user out.
    this.signalStoreService
      .selectOnStoreRestored('user')
      .pipe(
        switchMap(() => this.userQuery.selectCurrentUser(false)),
        filter(user => !user && this.appQuery.getIsLoggedIn())
      )
      .subscribe(() =>
        this.logout(true, null, null, {
          message: `Account Service failed to retrieve the current user`
        })
      )
  }

  ngOnDestroy(): void {}

  /**
   * Sets the temporarily saved credentials
   */
  set savedCredentials(credentials: { email: string; password: string }) {
    this.tempSavedCredentials = credentials
  }

  /**
   * Gets the temporarily saved credentials
   * @returns { email: string; password: string }
   */
  get savedCredentials(): { email: string; password: string } {
    return this.tempSavedCredentials
  }

  /**
   * Clears out the temporarily saved credentials
   */
  clearSavedCredentials(): void {
    this.tempSavedCredentials = null
  }

  updateAccount(account: AccountState): void {
    this.accountStore.updateAccount(account)
  }

  /**
   * Makes an API call to sign-up and login the user via OAuth
   * @returns {Observable<AuthResponse>}
   */
  socialConnect(oauthResponse: OAuthResponse, redirectURL?: string, queryParam?: string): Observable<AuthResponse> {
    const socialConnectBody = {
      provider: oauthResponse.provider,
      authorizationCode: oauthResponse.authorizationCode,
      idToken: oauthResponse.idToken,
      type: oauthResponse.origin === 'invitation' ? 'signup' : oauthResponse.origin,
      workspaceId: oauthResponse.workspaceId,
      invitationCode: oauthResponse.invitationCode,
      referralCode: oauthResponse.referralCode ? oauthResponse.referralCode : '',
      state: oauthResponse.state,
      validateEmailDomain:
        oauthResponse.origin === 'signup' && oauthResponse.provider === 'google' && !oauthResponse.fromMetaCampaign,
      connectInviteCode: oauthResponse.connectInviteCode
    }

    return this.apiClient
      .post<{
        subdomain: string
        email: string
        workspaceId: string
        accessToken: string
        userId: string
      }>(`${apiEndpoint}/invitations/socialconnect`, socialConnectBody)
      .pipe(
        mergeMap(resp => {
          if (queryParam) {
            this.queryParam = queryParam
          }

          if (oauthResponse.origin === 'signup') {
            return from(this.trackingService.trackSignUpStarted(resp.userId, resp.email)).pipe(
              switchMap(() => {
                return this.login(
                  resp.email,
                  generateGUID(),
                  resp.workspaceId,
                  { name: resp.subdomain, isDefault: true },
                  { provider: oauthResponse.provider, oauthAccessToken: resp.accessToken, userId: resp.userId }
                )
              })
            )
          }

          if (redirectURL) {
            this.redirectUrl = redirectURL
          }

          return this.login(
            resp.email,
            generateGUID(),
            resp.workspaceId,
            { name: resp.subdomain, isDefault: true },
            { provider: oauthResponse.provider, oauthAccessToken: resp.accessToken, userId: resp.userId }
          )
        }),
        catchError(error => this.onLoginError(error))
      )
  }

  /**
   * Makes an API call to add the OAuth integration to the account
   * @returns {Observable<AuthResponse>}
   */
  socialConnectAccount(oauthResponse: OAuthResponse): Observable<void> {
    return this.apiClient.post<void>(`${this.url}connecttosocialaccount`, {
      code: oauthResponse.authorizationCode,
      provider: oauthResponse.provider
    })
  }

  /**
   * Makes an API call to remove the OAuth integration from the account
   * @returns {Observable<AuthResponse>}
   */
  socialDisconnectAccount(provider: string): Observable<void> {
    return this.apiClient.post<void>(`${this.url}disconnecttosocialaccount`, { provider }).pipe(
      tap(() => {
        const account = this.accountQuery.getAccount()
        const filteredProviders = account.externalAccounts.filter(
          externalAccount => externalAccount.provider !== provider
        )

        switch (provider) {
          case 'google':
            this.accountStore.updateAccount({ googleUserId: null, externalAccounts: filteredProviders })
            break
          case 'apple':
            this.accountStore.updateAccount({ appleUserId: null, externalAccounts: filteredProviders })
        }
      })
    )
  }

  /**
   * Makes an API call to request login
   * @param {string} email
   * @param {string} password
   * @param {string} workspaceId
   * @param {string} subdomain
   * @param {Object} options - Additional options to configure during login
   * @param {string} options.provider
   * @param {string} options.oauthAccessToken
   * @param {string} options.userId
   * @param {string} options.emailVerificationUrlParams - all the parameters that we want to be provided to the email verification link
   * @returns {Observable<AuthResponse>}
   */

  public login(
    email: string,
    password: string,
    workspaceId: string,
    subdomain: Subdomain,
    options: {
      provider?: string
      oauthAccessToken?: string
      userId?: string
      emailVerificationUrlParams?: string
    } = {}
  ): Observable<AuthResponse> {
    const { provider, oauthAccessToken, userId } = options
    let url = ''

    if (environment !== 'local' && app === 'web' && subdomain) {
      url = `${apiProtocol}${subdomain.name}.${this.hostname}${this.url}${this.loginEndpoint}`
    } else {
      url = `${this.url}${this.loginEndpoint}`
    }

    this.email = email

    const currentTimeZone = this.browserService.getTimezone()

    const authHeader = isLiveMobile ? { authorization: `Basic ${mobileAppClientCredentials}` } : {}
    const otherHeaders = oauthAccessToken
      ? { timezone: currentTimeZone, aw_connect: oauthAccessToken }
      : { timezone: currentTimeZone }

    return this.apiClient
      .post<AuthResponse>(
        url,
        this.getBody({
          nonce: encrypt(clientSecret, JSON.stringify({ workspaceId, date: new Date() })),
          grant_type: isLiveMobile ? 'password' : null,
          scope: isLiveMobile ? 'offline_access full_access' : null,
          username: email,
          password,
          workspace_id: workspaceId,
          provider,
          url_params: options?.emailVerificationUrlParams
        }),
        {
          headers: new HttpHeaders({
            ...authHeader,
            ...otherHeaders
          })
        }
      )
      .pipe(
        map(resp => {
          resp.userId = userId
          return this.processLoginResponse(resp, email, subdomain)
        }),
        catchError(error => {
          if (error?.error?.code === 'email-not-verified' && app === 'web') {
            this.tempSavedCredentials = {
              email,
              password
            }
          }

          return this.onLoginError(error, subdomain)
        })
      )
  }

  /**
   * Processes the login response and redirects the user to the app
   * @param resp
   * @param email
   * @param subdomain
   */
  private processLoginResponse(resp: AuthResponse, email: string, subdomain: Subdomain): AuthResponse {
    this.setSubdomainCookie(subdomain)

    this.clearSavedCredentials()

    resp.subdomain = subdomain

    // If the user attempted to access an internal page prior login, redirect to it after success login
    let params = location.search || '?'

    if (location.search && this.queryParam) {
      params += `&${this.queryParam}`
    }

    const redirect = this.redirectUrl || '/my/dashboard' + params

    const browserSubdomain = this.browserService.getSubdomain()

    // If the environment is not local and not a custom subdomain (www)
    // Redirect to the custom subdomain
    if (
      environment !== 'local' &&
      app === 'web' &&
      (!this.browserService.isCustomSubdomain() || browserSubdomain.toLowerCase() !== subdomain.name.toLowerCase())
    ) {
      // Encrypt object with email and token expiration date
      const encryptedParams = encrypt(
        clientSecret,
        JSON.stringify({
          email,
          expiresIn: resp.expires_in,
          redirect
        })
      )

      window.location.replace(
        `https://${subdomain.name}.${this.hostname}/login?d=${encodeURIComponent(encryptedParams)}`
      )
    } else {
      const account = new Account(email, resp.expires_in)

      this.accountStore.updateAccount({
        email,
        refreshToken: resp.refresh_token,
        accessToken: resp.access_token,
        refreshAt: account.refreshAt
      })

      this.appStore.update({ isLoggedIn: true })

      const isInMSTeams = this.appQuery.getIsInMSTeams()
      const isFirefoxOrEdge =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ||
        navigator.userAgent.toLowerCase().indexOf('edge') > -1

      if (isInMSTeams && !isFirefoxOrEdge) {
        // Wait until the store is set with the account information otherwise MS Teams will not have it
        forkJoin([
          this.signalStoreService.selectOnStorePersisted('account'),
          this.signalStoreService.selectOnStorePersisted('app')
        ]).subscribe(() => {
          authentication.notifySuccess('')
        })
        // Redirect only in web app
      } else if (app === 'web') {
        this._router.navigateByUrl(redirect)
      }
    }

    return resp
  }

  /**
   * Check if there is a awr param inside the url.
   * Then redirect to the url in which is specified in
   * the awr paramter,
   * @param url The current url
   */
  public appsFlyerRedirect(url: string): string {
    if (url) {
      if (url.includes('awr=')) {
        // extract redirect url and
        const redirect = decodeURIComponent(url.split('awr=')[1])
        if (redirect && redirect.includes('awork.com')) {
          return redirect
        }
      }
    }

    return null
  }

  /**
   * Handles login call's errors
   * @param {HttpErrorResponse} error
   * @param {Subdomain} subdomain
   * @returns {Observable<never>}
   * @private
   */
  private onLoginError(error: HttpErrorResponse, subdomain?: Subdomain): Observable<never> {
    switch (error.error.code) {
      case 'login-precondition-failed':
        this._router.navigate(['/login'])
        break
      case 'workspace-access-denied':
      case 'user-access-denied':
        this.removeSubdomainCookie()
        break
      case 'credential-login-forbidden':
      case 'social-login-forbidden':
        this.handleForbiddenLoginMethod(error.error.code, subdomain)
        break
      case 'email-not-verified':
        if (app === 'web') {
          this._router.navigate(['/verify-email'], {
            queryParams: { email: btoa(this.savedCredentials.email) },
            queryParamsHandling: 'merge'
          })
        }
        break
    }

    return throwError(error)
  }

  /**
   * API call to switch workspace
   * @param{string} workspaceId - The workspace id
   * @param{string} subdomain - The workspace subdomain
   * @returns{Observable<AuthResponse>} - The new refresh token
   */
  public switchWorkspace(workspaceId: string, subdomain: Subdomain): Observable<AuthResponse> {
    let url: string

    if (environment !== 'local' && app === 'web') {
      url = `${apiProtocol}${subdomain.name}.${this.hostname}${this.url}${this.refreshEndpoint}`
    } else if (app === 'mobile') {
      url = `${this.url}${this.refreshEndpoint}`
    } else {
      url = `${this.url}${this.refreshEndpoint}`
    }

    return this.apiClient
      .post<AuthResponse>(
        url,
        this.getBody({
          grant_type: isLiveMobile ? 'refresh_token' : null,
          nonce: encrypt(clientSecret, JSON.stringify({ workspaceId, date: new Date() })),
          workspace_id: workspaceId,
          refresh_token: this.accountQuery.getAccount().refreshToken
        }),
        {
          headers: new HttpHeaders(isLiveMobile ? { authorization: `Basic ${mobileAppClientCredentials}` } : {})
        }
      )
      .pipe(
        map(response => {
          if (app === 'mobile') {
            const accountState = this.accountQuery.getAccount()
            const email = accountState.email
            const refreshToken = accountState.refreshToken
            const account = new Account(email, response.expires_in)

            this.accountStore.updateAccount({
              email,
              refreshToken: response.refresh_token ? response.refresh_token : refreshToken,
              accessToken: response.access_token,
              refreshAt: account.refreshAt
            })

            const currentWorkspace = this.workspaceQuery.getCurrentWorkspace()

            // For mobile, this flag is used to trigger the synchronization
            if (currentWorkspace && currentWorkspace.id !== workspaceId) {
              this.appStore.update({ isSwitchingWorkspace: true })

              // Reset the flag after 2 sec
              setTimeout(() => this.appStore.update({ isSwitchingWorkspace: false }), 2000)
            }
          }

          this.workspaceStore.setCurrentWorkspace(workspaceId)

          return response
        }),
        catchError(error => {
          this.logService.sendLogDNA(
            'ERROR',
            `Error refreshing token. Workspace: ${workspaceId} ${subdomain}. ${JSON.stringify(error, null, 2)}`,
            error
          )
          return throwError(error)
        })
      )
  }

  /**
   * Refreshes the browser with the first subdomain in the workspace
   * @param {Workspace} workspace - The workspace selected by the user
   * @param {string} redirectUrl - The URL to redirect after the workspace is switched
   */
  switchWorkspaceAndRedirect(workspace: Workspace, redirectUrl?: string): void {
    const account = this.accountQuery.getAccount()
    if (workspace && account) {
      const email = account.email
      const refreshToken = account.refreshToken
      const subdomain = workspace.getDefaultSubdomain(this)

      if (email) {
        this.switchWorkspace(workspace.id, subdomain).subscribe(res => {
          this.appStore.update({ isSwitchingWorkspace: true })

          // Encrypt object with email and token expiration date
          const encryptedParams = encrypt(
            clientSecret,
            JSON.stringify({
              email,
              expiresIn: res.expires_in,
              redirect: redirectUrl
            })
          )

          let newUrl =
            environment !== 'local' ? `https://${subdomain.name}.${this.hostname}/` : 'http://localhost:4200/'

          newUrl = newUrl.concat(`login?d=${encodeURIComponent(encryptedParams)}`)

          if (environment === 'local' && res.access_token && (res.refresh_token || refreshToken)) {
            newUrl = newUrl.concat(`&refresh_token=${res.refresh_token ? res.refresh_token : refreshToken}`)
            newUrl = newUrl.concat(`&access_token=${res.access_token}`)
            newUrl = newUrl.concat(`&subdomain=${subdomain.name}`)
          }

          this.setSubdomainCookie(subdomain)
          this.clearStore()

          window.location.replace(newUrl)
        })
      }
    }
  }

  /**
   * Calculates the refresh time of the session based on the expiration of the current session
   * @returns {number} - Time in milliseconds
   */
  getRefreshTime(): number {
    const account = this.accountQuery.getAccount()
    if (account && account.refreshAt) {
      try {
        const now = new Date()
        const refreshDate = new Date(account.refreshAt)
        const offset = 6000 // 1 minute
        const refreshTime = refreshDate.getTime() - now.getTime() - offset
        return refreshTime > 0 ? refreshTime : 1
      } catch (error) {
        return 1
      }
    }

    return null
  }

  /**
   * Requests a refreshed session and updates the account
   * @param {boolean} reload - True to reload the page after the session is refreshed
   * @return {Observable<AuthResponse>}
   */
  public refreshSession(reload = true): Observable<AuthResponse | void> {
    if (!this.refreshingSession) {
      this.refreshingSession = true
      this.refreshingSession$.next(true)

      // store the current URL
      const currentUrl = window.location.href

      // Get current workspace
      const workspace = this.workspaceQuery.getCurrentWorkspace()
      if (workspace) {
        const subdomain = workspace.getDefaultSubdomain(this)

        // Make the call to get a refreshed token/cookie for the current workspace
        return this.switchWorkspace(workspace.id, subdomain).pipe(
          map(authResponse => {
            // Refresh store
            const accountState = this.accountQuery.getAccount()
            const account = new Account(accountState.email, authResponse.expires_in)

            this.accountStore.updateAccount({
              email: account.email,
              refreshToken: accountState.refreshToken,
              accessToken: authResponse.access_token,
              refreshAt: account.refreshAt
            })

            this.refreshingSession = false
            this.refreshingSession$.next(false)

            // Refresh the current page
            if (reload) {
              if (this.signalStoreService.isStorageInit()) {
                this.signalStoreService.selectOnStorePersisted('account').subscribe(() => {
                  window.location.replace(currentUrl)
                })
              } else {
                // Adds an extra delay for mobiles devices that can be slower when writing to the store
                const mobileDelay = app === 'web' ? 0 : 1000
                setTimeout(() => window.location.replace(currentUrl), DEFAULT_PERSIST_DELAY + mobileDelay)
              }
            }
          }),
          catchError(error => {
            this.refreshingSession = false
            this.refreshingSession$.next(false)

            this.logService.sendLogDNA('WARN', 'Refresh session failed', error)

            // logout the user
            this.logout(true, null, null, {
              message: `Account Service failed with error ${error.status}`,
              error
            })
            return throwError(error)
          })
        )
      } else {
        this.refreshingSession = false
        this.refreshingSession$.next(false)
        this.logout(true, null, null, { message: `Account Service failed` })
        return throwError(null)
      }
    } else {
      return throwError(null)
    }
  }

  /**
   * Makes an API call to validate the credentials
   * @param {String} email
   * @param {String} password
   * @returns {Observable<Object>}
   */
  public validate(email: string, password: string): Observable<Object> {
    this.email = email
    return this.apiClient.post<string>(
      `${this.url}${this.loginEndpoint}`,
      this.getBody({
        nonce: encrypt(clientSecret, JSON.stringify({ validate: true, date: new Date() })),
        username: email,
        password,
        validate_only: 'true'
      })
    )
  }

  /**
   * Makes an API call to request a password reset
   * @param {String} email
   * @returns {Observable<Object>}
   */
  forgotPassword(email: string): Observable<Object> {
    this.email = email
    return this.apiClient.post<string>(`${this.url}forgotPassword`, {
      email
    })
  }

  /**
   * Makes an API call to reset the password
   * If the password is reset successfully, it will login
   * @param {String} password
   * @param {String} email
   * @param {String} code
   * @param {String} workspaceId
   * @param {String} subdomain
   * @returns {Observable<Observable<AuthResponse>>}
   */
  resetPassword(
    password: string,
    email: string,
    code: string,
    workspaceId: string,
    subdomain: Subdomain
  ): Observable<Observable<AuthResponse>> {
    return this.apiClient
      .post<string>(`${this.url}resetPassword`, {
        password,
        email,
        code
      })
      .pipe(
        map(() => {
          return this.login(email, password, workspaceId, subdomain)
        })
      )
  }

  /**
   * Logout the user, saving some login data and cleaning the storage
   * @param {boolean} unauthorized - Logout caused by unauthorized
   * @param {boolean} globalRedirect - Redirect to global login page after logout
   * @param {boolean} workspaceDeleted
   * @param {LogInfo} logInfo - Sends a log with the provided log info
   */
  logout(unauthorized = false, globalRedirect = false, workspaceDeleted = false, logInfo?: Partial<LogInfo>): void {
    if (!globalRedirect && app === 'web') {
      this.getLoginData(unauthorized)
    } else {
      // If globalRedirect is true, loginData is not needed
      this.requestLogout(unauthorized, null, globalRedirect, workspaceDeleted)
    }

    if (logInfo) {
      this.logService.sendLogDNA(
        logInfo.level || 'WARN',
        logInfo.message,
        logInfo.error,
        logInfo.skipUser,
        logInfo.addAccountData
      )
    }
  }

  /**
   * Sends an API request to delete the cookie and clean the storage
   * @param {boolean} unauthorized
   * @param {LoginData} loginData
   * @param {boolean} globalRedirect
   * @param {boolean} workspaceDeleted
   */
  requestLogout(unauthorized: boolean, loginData: LoginData, globalRedirect = false, workspaceDeleted = false) {
    this.apiClient.post(`${this.url}logout`, {}).subscribe(() => {
      this.subscriptions.forEach(s => s.unsubscribe())

      // TODO: Do we need this?
      // localStorage.clear()

      this.removeSubdomainCookie()

      if (loginData?.email) {
        localStorageHelper.setItem('loginData', loginData)
      }

      this.appStore.update({ isLoggedIn: false })

      // is recommended, but I dont know why
      // this.trackingService.resetDistinctId()

      if (workspaceDeleted) {
        this.clearStore()
      } else {
        const byeUrl = this.appQuery.getIsInMSTeams() ? '/msteamslogin' : '/bye'

        if (!globalRedirect || environment === 'local') {
          const redirect = app === 'web' ? byeUrl : '/onboarding'
          this._router.navigate([redirect, unauthorized ? { unauthorized } : {}]).then(() => {
            this.clearStore()
          })
        } else {
          this.clearStore()
          window.location.replace(`https://app.${this.hostname}${byeUrl}`)
        }

        // In case being logged out because of being unauthorized, store previous URL in redirectURL
        if (unauthorized) {
          this.redirectUrl = this._router.url
        }
      }

      // clear tracking stuff
      this.trackingService.clear()
    })
  }

  /**
   * Clears all stores
   */
  clearStore(): void {
    this.signalStoreService.resetStores({ exclude: ['app', 'logs', 'workspace'] })

    this.appStore.update({ isLoggedIn: false, lastSyncDate: null })
    this.accountFetched = false

    if (app === 'mobile') {
      localStorageHelper.removeItem('awState')
    }
  }

  /**
   * Gets the login data to be stored and used in login page
   * @param {boolean} unauthorized
   */
  getLoginData(unauthorized: boolean): void {
    // Get user information to display it in login page
    const loginData = new LoginData()

    this.subscriptions.push(
      this.accountQuery
        .selectAccount()
        .pipe(take(1))
        .subscribe(account => {
          loginData.email = account.email

          const user = this.userQuery.getCurrentUser()

          if (user) {
            loginData.name = user.fullName
            loginData.initials = user.initials

            if (user.hasImage) {
              this.getUserBlobImage(user).subscribe(
                blob => {
                  this.getImageBase64FromBlob(blob).subscribe(res => {
                    loginData.image = res.currentTarget.result

                    this.requestLogout(unauthorized, loginData)
                  })
                },
                () => this.requestLogout(unauthorized, loginData)
              )
            } else {
              this.requestLogout(unauthorized, loginData)
            }
          } else {
            this.requestLogout(unauthorized, loginData)
          }
        })
    )
  }

  /**
   * Gets the user image blob
   * @param {User} user
   * @param {number} width
   * @param {number} height
   * @return {Observable<Blob>}
   */
  getUserBlobImage(user: User, width = 100, height = 100): Observable<Blob> {
    return this.apiClient.get<Blob>(user.profileImage(width, height), {
      responseType: 'blob'
    })
  }

  /**
   * Gets the image encoded in base64 from a blob
   * @param {Blob} image
   * @return {Observable<any>}
   */
  getImageBase64FromBlob(image: Blob): Observable<any> {
    const reader = new FileReader()

    const fileReader$ = fromEvent(reader, 'load')

    reader.readAsDataURL(image)

    return fileReader$
  }

  /**
   * Makes an API call to check if the account already exists
   * @param {String} email
   * @returns {Observable<ValidatedAccount>}
   */
  checkEmail(email: string): Observable<ValidatedAccount> {
    const obfuscatedEmail = rotateCharacters(email)
    const base64Email = btoa(obfuscatedEmail)
    const validateAccountBody = {
      value: base64Email
    }

    return this.apiClient.post<ValidateAccount>(`${this.url}validate`, validateAccountBody).pipe(
      map(response => {
        const numberPart = response.value.substring(0, response.value.length - 1)
        const signPart = response.value.substring(response.value.length - 1)
        const exists = parseInt(numberPart, 10) % 2 == 0
        const passwordIsAutoGenerated = signPart === '#'
        return {
          exists: exists,
          passwordIsAutoGenerated: passwordIsAutoGenerated
        }
      })
    )
  }

  /**
   * Makes an API call to check if the email is from a free domain
   * @param {string} email
   * @returns {Observable<boolean>}
   */
  checkEmailDomain(email: string): Observable<boolean> {
    return this.apiClient.post<boolean>(`${this.url}isfreemail`, { email })
  }

  /**
   * Encode a JSON as URL query params (param1=value1&param2=value2
   * @param {Object} json - The JSON to be encoded
   * @returns {string} query - The URL query
   */
  private getBody(json: Object): string {
    let query = ''
    for (const key in json) {
      if (json.hasOwnProperty(key) && json[key]) {
        query += encodeURIComponent(key) + '=' + encodeURIComponent(json[key]) + '&'
      }
    }
    return query.slice(0, -1)
  }

  /**
   * Set the redirect URL used when a user logs in after attempted to access with no credentials
   * @param {string} url
   */
  setRedirectUrl(url: string): void {
    // URLs that are part of the Auth module, no need to redirect there
    const exceptUrl = [
      'login',
      'forgot-password',
      'reset-password',
      'set-password',
      'signup',
      'imprint',
      'bye',
      'no-workspace'
    ]

    if (url !== '/' && !exceptUrl.some(string => url.indexOf(string) >= 0)) {
      this.redirectUrl = url
    } else {
      this.redirectUrl = void 0
    }
  }

  /**
   * Get the redirect URL used when a user logs in
   * @returns {string}
   */
  getRedirectUrl(): string | undefined {
    return this.redirectUrl
  }

  /**
   * Set a temporary credential to hold login information to proceed to
   * create a new workspace (user has no workspace)
   * @param {string} email
   * @param {string} password
   */
  setTemporaryCredentials(email: string, password: string): void {
    this.email = email
    this.password = password
  }

  /**
   * Gets the temporary credential used for workspace creation
   * (user has no workspace)
   * @return {Object}
   */
  getTemporaryCredentials(): { email: string; password: string } {
    return {
      email: this.email,
      password: this.password
    }
  }

  /**
   * Gets the account's details from the store and make and API call to update it
   * @return {Observable<Account>}
   */
  getAccountDetails(): Observable<Account> {
    // If the account has not been fetched (called by the guard for the first time), fetch it
    if (!this.accountFetched) {
      return this.fetchAccountDetails()
    } else {
      return this.accountQuery.selectAccount().pipe(
        mergeMap(account => {
          if (account && account.id) {
            if (!this.accountFetched) {
              this.fetchAccountDetails().subscribe()
            }
            return of(Account.fromState(account))
            // else, account need to be fetched again (Ex: store has been wiped (just logout))
          } else {
            this.accountFetched = false
            return null
          }
        })
      )
    }
  }

  /**
   * Makes an API call to get the user account's details and store it
   * @return {Observable<Account>}
   */
  public fetchAccountDetails(): Observable<Account> {
    const account = this.accountQuery.getAccount()
    let email = account.email

    if (account.isImpersonated && account.email.includes('-AW-')) {
      email = account.email.split('-AW-')[1]
    }

    return this.apiClient.get<Account>(`${this.url}${encodeURIComponent(email)}`).pipe(
      map(accountDetails => {
        this.accountFetched = true
        Object.assign(account, accountDetails)
        this.accountStore.updateAccount(account)
        this.appStore.update({ language: account.language })
        return Account.fromState(account)
      })
    )
  }

  /**
   * Makes an API call to updates the user account's details
   * @param {Account} account
   * @return {Observable<Account>}
   */
  updateAccountDetails(account: Account): Observable<Account> {
    const accountPost = {
      firstName: account.firstName,
      lastName: account.lastName,
      language: account.language,
      timezone: account.timezone,
      email: account.email
    }

    const operationId = this.accountStore.history.startOperation()
    const appStoreOperationId = this.appStore.history.startOperation()

    this.accountStore.updateAccount(account)
    this.appStore.update({ language: account.language })

    return this.apiClient.put<Account>(`${this.url}${account.id}`, accountPost).pipe(
      map(newAccount => {
        this.accountStore.updateAccount(newAccount)
        this.appStore.update({ language: account.language })
        this.accountStore.history.endOperation(operationId)
        this.appStore.history.endOperation(appStoreOperationId)
        return newAccount
      }),
      catchError(error => {
        this.accountStore.history.undo(operationId)
        this.appStore.history.undo(appStoreOperationId)
        return throwError(error)
      })
    )
  }

  /**
   * Makes an API call to updates the user account's password
   * @param {string} currentPassword
   * @param {string} newPassword
   * @return {Observable<string>}
   */
  updatePassword(currentPassword: string, newPassword: string): Observable<string> {
    return this.apiClient.post<string>(`${this.url}changepassword`, {
      currentPassword,
      newPassword
    })
  }

  /**
   * Makes an API call to check the validity of the password reset code
   * @param {string} email
   * @param {string} code
   * @return {Observable<boolean>}
   */
  checkResetPasswordCode(email: string, code: string): Observable<boolean> {
    return this.apiClient
      .post<HttpResponse<void>>(
        `${this.url}isresetpasswordcodevalid`,
        {
          email,
          code
        },
        { observe: 'response' }
      )
      .pipe(
        map(response => {
          return response.status === 200
        })
      )
  }

  /**
   * Checks if the subdomain cookie is set, and then redirect to that subdomain
   */
  checkSubdomainCookie(performRedirect = true, redirectUrl?: string, queryParams?: Object): boolean {
    // check if it is a forward from appsflyer, then check query parameter awr for redirection
    this.appsFlyerRedirect(location.href)

    const subdomain = getCookie(this.subdomainCookie)
    const actualSubdomain = this.browserService.getSubdomain()
    const customSubdomain = this.browserService.isCustomSubdomain()

    // Get the internal redirect url
    const redirect = this.getRedirectUrl() ? this.getRedirectUrl() : ''

    // If the subdomain cookie is set, is different than the current
    // and the actual subdomain is not custom, redirect to that subdomain
    if (subdomain && subdomain !== actualSubdomain && !customSubdomain && environment !== 'local') {
      if (performRedirect) {
        let url = `https://${subdomain}.${this.hostname}${redirectUrl ? redirectUrl : redirect}`
        url = url.replace(/\/$/, '')

        // Preserve query params
        const queryString = queryParams ? `?${buildQueryString(queryParams)}` : location.search

        window.location.replace(queryParams ? `${url}${queryString}` : url)
      }
      return true
    }
    return false
  }

  /**
   * Sets the subdomain cookie used to redirect the user to the workspace's subdomain
   * @param {Subdomain} subdomain
   */
  private setSubdomainCookie(subdomain: Subdomain): void {
    setCookie(this.subdomainCookie, subdomain.name, {
      domain: this.hostname,
      path: '/',
      expires: addMonths(new Date(), 1)
    })
  }

  /**
   * Removes the subdomain cookie used to redirect the user to the workspace's subdomain
   */
  removeSubdomainCookie(): void {
    removeCookie(this.subdomainCookie, {
      domain: this.hostname,
      path: '/'
    })
  }

  /**
   * Handles forbidden login method (credentials and social login) in case of workspaces with SSO exclusively
   * @param {string} errorCode
   * @param {Subdomain} subdomain
   */
  private handleForbiddenLoginMethod(errorCode: string, subdomain: Subdomain): void {
    if (app === 'web') {
      window.location.replace(`https://${subdomain.name}.${this.hostname}/login?error=${errorCode}`)
    }
  }

  /**
   * Makes an API call to send a verification email
   * @param {string} email
   * @return {Observable<void>}
   */
  sendVerificationEmail(email: string): Observable<void> {
    return this.apiClient.post<void>(`${this.url}/sendVerificationEmail`, { email })
  }

  /**
   * Makes an API call to verify the email and updates the account with the new email
   * When updating the user email, we need to update the account store after the email is verified,
   * since the api doesn't return the new Account model
   * @param {Account} account
   * @param {string} newEmail
   * @param {string} code
   * @return {Observable<void>}
   */
  verifyNewEmail(account: Account, newEmail: string, code: string): Observable<void> {
    return this.verifyEmail(account.email, code).pipe(
      tap(() => {
        const updatedAccount = Object.assign(account, { email: newEmail, emailConfirmed: true })
        this.accountStore.updateAccount(updatedAccount)
      })
    )
  }

  /**
   * Makes an API call to verify the email manually
   * @param {string} email
   * @param {string} code
   * @return {Observable<void>}
   */
  verifyEmail(email: string, code: string): Observable<void> {
    return this.apiClient.post<void>(`${this.url}/verifyEmail`, { email: email, code })
  }

  /**
   * Fetches the business domains verified and available for same-domain signup
   * @param {string} workspaceId
   * @returns {Observable<string[]>}
   */
  getConfirmedBusinessDomains(workspaceId: string): Observable<string[]> {
    return this.apiClient.get<string[]>(`${this.url}/byworkspaceid/${workspaceId}/confirmedbusinessdomains`)
  }
}
